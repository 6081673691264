import React from "react";
import "./MonthlyBookKeeping.scss";
import CatchupAccountingBanner from "./CatchupAccountingBanner/CatchupAccountingBanner";
import WhatCatch from "./WhatCatch/WhatCatch";
import CatchServices from "./CatchServices/CatchServices";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import AllServices from "../../components/AllServices/AllServices";

function MonthlyBookKeeping() {
  return (
    <div>
      <CatchupAccountingBanner />
      <WhatCatch />
      <CatchServices />
      <OurProcess />
      <TestimonialCards />

      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
      <div className="vistual-explore-button">
        <AllServices />
      </div>
      <div className="container">
        <LatestBlog />
      </div>
    </div>
  );
}

export default MonthlyBookKeeping;
