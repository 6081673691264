import "./AboutUs.scss";
import React from "react";
import CompanyImg from "../../assets/images/Company-img.svg";
import GolaImg from "../../assets/images/goal-img.png";
import GoalImg2 from "../../assets/images/bro.png";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import OurProcess from "../../components/OurProcess/OurProcess";
import MemberImage1 from "../../assets/images/jitendra-round.png";
import MemberImage2 from "../../assets/images/sumit-round.png";
import AnimatedSection from "../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

function AboutUs() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="main-aboutus-wrapper">
      <div className="aboutus-content-wrapper">
        <div className="aboutus-banner-wrapper">
          <div>
            <p className="banner-title">Welcome to FinAccle</p>
            <p className="aboutus-small-text">Your Premier Financial Partner</p>
          </div>

          <div className="right-side-img-alignment">
            <img src={CompanyImg} alt="CompanyImg" />
          </div>
        </div>

        <div className="container">
          <div className="aboutus-content-alignment">
            <div className="about-finaccle-wrapper">
              <div className="about-member-image-wrapper member-left-alignment">
                <div className="member-left-image-alignment ">
                  <div className="member-image">
                    <img src={MemberImage1} alt="" />
                  </div>
                  <div className="member-name">Jitendra</div>
                </div>
              </div>
              <div className="about-desc-wrapper">
                <div className="about-finaccle-title">About Finaccle</div>
                <span className="top-content">
                  Finaccle has carved a niche in the accounting and financial
                  services industry with over 6 years of experience. Under the
                  expert guidance of CA Sumit Pokharna and CA Jitendra Jain, we
                  operate branches in both Surat and Mumbai. To date, we have
                  proudly served over 400 clients, delivering precise and
                  innovative accounting solutions tailored to meet diverse
                  needs.
                </span>
                <span className="top-content">
                  Our firm is recognized for its proficiency in an array of
                  software tools including Quickbook, Zoho, Tally, Xero, Hubdoc,
                  Net Suite, and Odoo, which enhance our ability to manage
                  complex financial landscapes effectively.
                </span>
              </div>
              <div className="about-member-image-wrapper member-right-alignment">
                <div className="member-right-image-alignment">
                  <div className="member-image">
                    <img src={MemberImage2} alt="" />
                  </div>
                  <div className="member-right-name">Sumit</div>
                </div>
              </div>
            </div>

            <div className="mission-vision-wrapper">
              <AnimatedSection
                animationType={isMobile ? "fade" : "fade-up"}
                duration="900"
              >
                <div className="mission-vision-card">
                  <button>Mission</button>
                  <p>
                    Our mission is to deliver exceptional financial clarity and
                    support to our clients through comprehensive accounting
                    services. We aim to streamline their financial operations,
                    reduce complexities, and foster sustainable growth by
                    leveraging advanced technology and personalized service. At
                    Finaccle, we are committed to being more than just service
                    providers; we strive to be trusted advisors who stand by our
                    clients in navigating the ever-evolving financial
                    environment.
                  </p>
                </div>
              </AnimatedSection>
              <AnimatedSection
                animationType={isMobile ? "fade" : "fade-up"}
                duration="900"
              >
                <div className="mission-vision-card">
                  <button>Vision</button>
                  <p>
                    Finaccle aspires to set the benchmark for excellence and
                    innovation in the financial services sector. Our vision is
                    to expand our reach, both domestically and internationally,
                    while maintaining the highest standards of integrity and
                    professionalism. We aim to empower our clients by
                    transforming their accounting systems with cutting-edge
                    technology and expert insights, thus ensuring they are
                    well-prepared for the future.
                  </p>
                </div>
              </AnimatedSection>
            </div>
          </div>
        </div>

        <div className="aboutus-button-alignment">
          <BookCallBannerButton />
        </div>

        <div className="container">
          <div className="aboutus-details-section-wrapper">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-up-right"}
              duration="900"
            >
              <div className="details-section-content">
                <div className="section-wrapper">
                  <div className="section-title">
                    Financial and Accounting Outsourcing Specialists
                  </div>
                  <div className="section-description">
                    Finaccle provides efficient outsourced bookkeeping,
                    accounting, taxation, and advisory services worldwide,
                    tailored for startups and SMEs. Our aim is to optimize
                    financial processes, delivering cost-effective solutions
                    with precision and expertise
                  </div>
                </div>
                <div className="section-wrapper">
                  <div className="section-title">
                    Innovative Solutions for Modern Businesses
                  </div>
                  <div className="section-description">
                    Embrace innovation with FinAccle. Our current technology,
                    combined with an understanding of over 20 accounting
                    software, guarantees that your financial tactics are
                    efficient, accurate, and custom-designed to fit your
                    enterprise needs. Focus on growth even as we cope with the
                    numbers.
                  </div>
                </div>
                <div className="section-wrapper">
                  <div className="section-title">
                    Your Industry Expertise Partner
                  </div>
                  <div className="section-description">
                    From healthcare to e-commerce, hospitality to actual estate,
                    we boast a wealth of revelations across diverse sectors. Our
                    tailor-made answers cope with your industry-specific
                    demanding situations, empowering your enterprise to thrive
                    in brand brand-new aggressive panorama.
                  </div>
                </div>
              </div>
            </AnimatedSection>
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-up-left"}
              duration="900"
            >
              <div className="details-section-image">
                <img src={GolaImg} alt="GolaImg" />
              </div>
            </AnimatedSection>
          </div>
        </div>

        <div className="container">
          <div className="aboutus-details-section-wrapper">
            <div className="details-section-image">
              <AnimatedSection
                animationType={isMobile ? "fade" : "fade-up-right"}
                duration="900"
              >
                <img src={GoalImg2} alt="GoalImg2" />
              </AnimatedSection>
            </div>
            <AnimatedSection animationType={isMobile ? "fade" : "fade-up-left"} duration="900">
              <div className="details-section-content">
                <div className="section-wrapper">
                  <div className="section-title">Who are we?</div>
                  <div className="section-description">
                    We are a team of experienced Chartered Accountants and
                    industry professionals dedicated to maintaining your
                    financial records flawlessly throughout the year.
                  </div>
                  <div className="section-description">
                    With expert insights and tailored advice, we help you
                    optimize your company's finances to succeed.
                  </div>
                  <div className="section-description">
                    Our tech-driven approach, proficiency in leading accounting
                    software such as Tally, SAP, QuickBooks and Xero, ensures a
                    high level of service. Count on us for accurate and timely
                    results that exceed expectations.
                  </div>
                </div>
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <div className="softwrae-expertise-details">
        <SoftwareExpertise />
      </div>
      <div className="container">
        <WhyChooseUs />
      </div>

      <div className="aboutus-button-alignment">
        <BookCallBannerButton />
      </div>
      <div className="container">
        <OurProcess />
      </div>

      <div className="container">
        <div className="our-promise-section">
          <div className="our-promise-heading">
            <h2>Our Promise</h2>
            <p>
              "Cost-Effective Solutions": Increase revenue even by lowering
              fees. "Resource Planning and Retention": Ensuring premier
              performance for consumer pride.
            </p>
          </div>

          <h3>
            Ready to revolutionise your financial journey? Let's get on a
            seamless partnership towards your business success.{" "}
          </h3>
        </div>
      </div>
      <div className="container">
        <div className="connect-button">
          <button
            onClick={() => {
              window.open(
                "https://calendly.com/info-finaccleglobal/30min",
                "_blank"
              );
            }}
          >
            Connect with us today!
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
