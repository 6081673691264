import React, { useContext } from "react";
import "./AccountOutsourcingBanner.scss";
import CollebarteImg from "../../../assets/images/cfm-banner.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { GlobalContext } from "../../../store/GlobalState";

export default function AccountOutsourcingBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="account-outsourcing-banner-section ">
      <div className="container">
        <div className="banner-grid">
          <div className="bannner-left-wrapper">
            <div className="bannner-left">
              <img src={CollebarteImg} alt="CollebarteImg" />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Cash Flow Management (CFM) {state.country}</h1>
            <h2>
              At Finaccle, we offer effective cash flow management solutions,
              and it can help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Monitoring and optimizing cash flow</li>
                <li>Ensuring sufficient liquidity for operations</li>
                <li>Identifying and mitigating cash flow risks</li>
                <li>Implementing best practices for financial stability</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>WHAT IS CASHFLOW MANAGEMENT?</h2>
          <p>
            Cash flow management is the process of monitoring, analyzing, and
            optimizing the inflow and outflow of cash within a business.
          </p>
          <p>
            It involves ensuring that a company has sufficient funds available
            to meet its financial obligations, such as paying bills, salaries,
            and loan repayments.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
