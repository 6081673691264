import React, { useContext } from "react";
import "./AllServices.scss";
import AnimatedSection from "../Animation/AnimatedSection";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../store/GlobalState";

function AllServices() {
  const navigate = useNavigate();
  const { state } = useContext(GlobalContext);

  const ourServicesData = [
    {
      serviceTitle: "Virtual CFO",
      routeName: `/virtual-cfo/${state.country}`,
    },
    {
      serviceTitle: "Accounts Payable",
      routeName: `/payable/${state.country}`,
    },
    {
      serviceTitle: "Accounts Receivable",
      routeName: `/receivable/${state.country}`,
    },
    {
      serviceTitle: "Billing And Invoicing",
      routeName: `/billing-and-invoicing/${state.country}`,
    },
    {
      serviceTitle: "Reconciliation",
      routeName: `/reconciliation/${state.country}`,
    },
    {
      serviceTitle: "Customer Order Processing",
      routeName: `/customer-order-processing/${state.country}`,
    },
    {
      serviceTitle: "Accounts Outsourcing",
      routeName: `/account-outsourcing/${state.country}`,
    },
    {
      serviceTitle: "Off Shore Accounting",
      routeName: `/off-shore-accounting/${state.country}`,
    },
    {
      serviceTitle: "Net Suite Accounting",
      routeName: `/net-suite-accounting/${state.country}`,
    },
    {
      serviceTitle: "Zoho Books Accounting",
      routeName: `/zoho-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Quick Books Accounting",
      routeName: `/quick-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Xero Accounting",
      routeName: `/xero/${state.country}`,
    },
    {
      serviceTitle: "Catch Up Accounting",
      routeName: `/catch-up-accounting/${state.country}`,
    },
    {
      serviceTitle: "Monthly Bookkeeping",
      routeName: `/monthly-book-keeping/${state.country}`,
    },
    {
      serviceTitle: "Retro Accounting",
      routeName: `/retro-accounting/${state.country}`,
    },
    {
      serviceTitle: "Payroll",
      routeName: `/payroll/${state.country}`,
    },
    {
      serviceTitle: "Financial Reporting",
      routeName: `/financial-reporting/${state.country}`,
    },
    {
      serviceTitle: "Budgeting And Forecasting",
      routeName: `/budgeting-and-forecasting/${state.country}`,
    },
    {
      serviceTitle: "CFM",
      routeName: `/cfm/${state.country}`,
    },
  ];

  return (
    <div className="container">
      <div className="all-served-to-wrapper">
        <div className="all-our-services-title">Our All Services</div>
        <AnimatedSection animationType="fade-up" duration="1000">
          <div className="all-served-to-card-wrapper">
            {ourServicesData?.slice(0, 4).map((data, index) => {
              return (
                <div
                  className="all-served-to-card-content"
                  key={index}
                  style={{
                    width: "246px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(data.routeName)}
                >
                  <div
                    className="all-card-title"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {data.serviceTitle}
                  </div>
                </div>
              );
            })}
          </div>
        </AnimatedSection>

        <AnimatedSection animationType="fade-up" duration="1000">
          <div
            className="all-served-to-card-wrapper"
            style={{ marginTop: "30px" }}
          >
            {ourServicesData?.slice(4, 8).map((data, index) => {
              return (
                <div
                  className="all-served-to-card-content"
                  key={index}
                  style={{
                    width: "246px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(data.routeName)}
                >
                  <div
                    className="all-card-title"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {data.serviceTitle}
                  </div>
                </div>
              );
            })}
          </div>
        </AnimatedSection>

        <AnimatedSection animationType="fade-up" duration="1000">
          <div
            className="all-served-to-card-wrapper"
            style={{ marginTop: "30px" }}
          >
            {ourServicesData?.slice(8, 12).map((data, index) => {
              return (
                <div
                  className="all-served-to-card-content"
                  key={index}
                  style={{
                    width: "246px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(data.routeName)}
                >
                  <div
                    className="all-card-title"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {data.serviceTitle}
                  </div>
                </div>
              );
            })}
          </div>
        </AnimatedSection>

        <AnimatedSection animationType="fade-up" duration="1000">
          <div
            className="all-served-to-card-wrapper"
            style={{ marginTop: "30px" }}
          >
            {ourServicesData?.slice(12, 16).map((data, index) => {
              return (
                <div
                  className="all-served-to-card-content"
                  key={index}
                  style={{
                    width: "246px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(data.routeName)}
                >
                  <div
                    className="all-card-title"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {data.serviceTitle}
                  </div>
                </div>
              );
            })}
          </div>
        </AnimatedSection>

        <AnimatedSection animationType="fade-up" duration="1000">
          <div
            className="all-served-to-card-wrapper"
            style={{ marginTop: "30px" }}
          >
            {ourServicesData?.slice(16).map((data, index) => {
              return (
                <div
                  className="all-served-to-card-content"
                  key={index}
                  style={{
                    width: "246px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(data.routeName)}
                >
                  <div
                    className="all-card-title"
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {data.serviceTitle}
                  </div>
                </div>
              );
            })}
          </div>
        </AnimatedSection>
      </div>
    </div>
  );
}

export default AllServices;
