import React, { useContext } from "react";
import "./VirtualBanner.scss";
import VirtualBannerImg from "../../../assets/images/virtual-banner-img.png";
import { GlobalContext } from "../../../store/GlobalState";
export default function VirtualBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="container">
      <div className="virtual-banner-section">
        <div className="virtual-banner-grid">
          <div className="virtual-banner-img-wrapper">
            <div className="virtual-banner-img">
              <img src={VirtualBannerImg} alt="VirtualBanner" />
            </div>
          </div>

          <div className="virtual-banner-text">
            <h1>Virtual CFO Services {state.country}</h1>
            <h2>
              At Finaccle we provide virtual CFO solution, and it can help you
              with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Accounting & Bookkeeping</li>
                <li>Implementing best financial practices for your business</li>
                <li>Financial Analysis</li>
                <li>Business Valuation</li>
                <li>Funding Evaluation</li>
                <li>Business Acquisition & Mergers</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
