import React, { useContext } from "react";
import "./AccountPayebleBanner.scss";
import AccountPayebleBannerImg from "../../../assets/images/invoices-banner.svg";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function AccountPayebleBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="account-payeble-banner-section">
      <div className="container">
        <div className="account-payeble-banner-grid">
          <div className="account-payeble-banner-img-wrapper">
            <div className="account-payeble-banner-img">
              <img
                src={AccountPayebleBannerImg}
                alt="AccountPayebleBannerImg"
              />
            </div>
          </div>

          <div className="virtual-banner-text">
            <h1>Billing and Invoicing {state.country}</h1>
            <h2>
              At Finaccle we provide billing and invoicing solutions, and it can
              help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Automating your billing cycles</li>
                <li>Ensuring accurate and timely invoicing</li>
                <li>Reducing billing errors</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>Invoicing</h2>
          <p>
            Invoice processing is crucial for businesses as they provide
            products or services in exchange for value. As documentation or
            evidence of transactions, invoices are a must. The number of
            invoices depends on the volume of transactions, and inaccuracies can
            lead to customer dissatisfaction.
          </p>
          <p>
            Invoices are processed by the Accounts Payable Department and can be
            handled manually or through automated software. However, manual
            processing can be time-consuming and investment in automated
            software may not be convenient or cost-effective for some
            businesses.
          </p>
          <p>
            Outsourcing invoice processing can help businesses achieve
            high-level efficiency and speed up accounts payable receivable.
          </p>
          <p>
            Finaccle offers invoice processing services to Indian businesses,
            helping them achieve high-level efficiency and speed up accounts
            payable receivable. Understanding the importance of invoices in
            business efficiency is essential for businesses to manage their
            invoices effectively.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
