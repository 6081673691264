import React from "react";
import "./PayableServices.scss";
import ServicesImg1 from "../../../assets/images/services-1.png";
import ServicesImg2 from "../../../assets/images/services-2.png";
import ServicesImg3 from "../../../assets/images/Experience-bro.svg";
import ServicesImg4 from "../../../assets/images/CashFlow-bro.svg";
import ServicesImg5 from "../../../assets/images/reduced-risk-bro.svg";
import ServicesImg6 from "../../../assets/images/services-4.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function PayableServices() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="payableServicesSection">
      <div className="container">
        <div className="payableServicesHeading">
          <h2>
            Advantages of Outsourcing Accounts Receivable Management System
          </h2>
        </div>
      </div>

      <div className="container">
        <div
          className="payableServicesDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Cost Effectiveness</h3>
                <p>
                  Outsourcing Account Receivable Management System can lower the
                  operational expenses involved with maintaining an in-house
                  workforce, infrastructure, and technology essential for
                  effective receivables management. We have specialized systems
                  and experience, allowing firms to use these resources for a
                  fraction of the cost.
                </p>
              </div>
            </AnimatedSection>
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg1} alt="ServicesImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg2} alt="ServicesImg2" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Focus on Core capabilities</h3>
                <p>
                  By outsourcing Account Receivable Management System to a
                  specialist service provider, organizations can concentrate on
                  their core capabilities and strategic goals rather to being
                  bogged down by administrative activities. This might result in
                  increased productivity and innovation inside the firm.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Access to experience</h3>
                <p>
                  Outsourcing Account Receivable Management System provides
                  firms with specialized experience and best practices in
                  receivable management. Outsourcing providers frequently employ
                  seasoned individuals who are well-versed in industry
                  standards, legislation, and methods for improving cash flow
                  and lowering bad debt.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg3} alt="ServicesImg3" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg4} alt="ServicesImg4" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Improved Cash Flow</h3>
                <p>
                  Effective receivables management helps speed up cash flow by
                  lowering the time it takes to collect payments from clients.
                  Outsourcing providers can help organizations enhance cash flow
                  and working capital management by implementing streamlined
                  processes, automated reminders, and effective collection
                  techniques.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Reduced Risk</h3>
                <p>
                  Outsourcing Account Receivable Management System can assist
                  reduce the risks connected with non payment, late payments,
                  and bad debts. Outsourcing providers frequently have strong
                  credit risk assessment processes, collection tactics, and
                  compliance controls in place to reduce the impact of late
                  accounts on the organization.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg5} alt="ServicesImg5" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg6} alt="ServicesImg6" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Compliance and Regulatory Adherence</h3>
                <p>
                  Outsourcing providers stay up to date on the newest
                  regulations and compliance requirements for receivables
                  management. Businesses that collaborate with a reliable
                  outsourcing provider may assure compliance with relevant laws
                  and industry standards, lowering the chance of legal
                  difficulties and penalties.
                </p>
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
