import React from "react";
import "./AutomationServices.scss";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function AutomationServices() {
  return (
    <div className="automation-services-section">
      <div className="container">
        <div className="automation-services-heading">
          <h2>Our Tailored Accounts Receivable Outsourcing Services</h2>
          <p>
            We provide the following Accounts Receivable Outsourcing Services
          </p>
        </div>

        <div className="automation-services-details-alignment">
          <div className="automation-services-grid">
            <div className="automation-services-grid-item">
              <div className="automation-services-box">
                <h4>Account Receivable Outsourcing Services</h4>
                <p
                  style={{
                    textWrap: "wrap",
                  }}
                >
                  Accounts Receivable Outsourcing Services to ease financial
                  operations and ensure effective cash management for your
                  business. Explore our comprehensive, accounting outsourcing
                  services tailored to meet your unique needs and goals:
                </p>
              </div>

              <div className="automation-services-box">
                <h4>Payment Processing and Posting</h4>
                <p
                  style={{
                    textWrap: "wrap",
                  }}
                >
                  Processing customer payments efficiently, posting them
                  accurately to your accounting system, discrepancies, and
                  exception management.
                </p>
              </div>

              <div className="automation-services-box">
                <h4>Reconciliation and Reporting</h4>
                <p
                  style={{
                    textWrap: "wrap",
                  }}
                >
                  Ensuring the accuracy of financial record keeping, backed by
                  extensive reports and dashboards that provide up-to-date
                  information in real time.
                </p>
              </div>
            </div>

            <div className="automation-services-grid-item">
              <div className="automation-services-box">
                <h4>Customer Support</h4>
                <p
                  style={{
                    textWrap: "wrap",
                  }}
                >
                  Customer service team to answer all questions and complaints
                  of your customers, ensuring positive experience and customer
                  satisfaction as well as quick fixes.
                </p>
              </div>

              <div className="automation-services-box">
                <h4>Collections Management</h4>
                <p
                  style={{
                    textWrap: "wrap",
                  }}
                >
                  Follow up systematically on overdue payments by issuing
                  reminders notes on credit, and escalation notices for faster
                  collection of receivables and reduction of DSOs.
                </p>
              </div>

              <div className="automation-services-box">
                <h4>Invoice Generation and Distribution</h4>
                <p
                  style={{
                    textWrap: "wrap",
                  }}
                >
                  Prompt invoicing to your customers in accordance with your
                  invoicing policies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
