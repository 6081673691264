import "./TestimonialCards.scss";
import React from "react";
import Client1 from "../../assets/images/client-1.png";
import Client2 from "../../assets/images/client-2.png";
import Client3 from "../../assets/images/client-3.png";
import Client4 from "../../assets/images/client-4.png";

function TestimonialCards() {
  return (
    <div className="container">
      <div className="testimonial-wrapper">
        <div className="testimonial-title-wrapper">
          <div className="testimonial-title">Testimonial</div>
          <div className="testimonial-description">
            Hear What Our Clients Have to Say
          </div>
        </div>
        <div className="testimonial-card-wrapper">
          <div className="testimonial-card ">
            <div className="testimonial-content">
              <span className="semicolor-content">"</span>
              <span className="testimonial-text">
                It's precise to paintings with Finaccle's Advisors. Perfect and
                Reliable.
              </span>
            </div>
            <div className="testimonial-client">
              <img src={Client1} alt="Client 1" />
              <span>Mr. Sharma</span>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-content">
              <span className="semicolor-content">"</span>
              <span className="testimonial-text">
                Quick response to any query. Excellent offerings and Extremely
                polite and professional workforce.
              </span>
            </div>
            <div className="testimonial-client">
              <img src={Client2} alt="Client 2" />
              <span>Abhishek Taliya</span>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-content">
              <span className="semicolor-content">"</span>
              <span className="testimonial-text">
                Best in the business of their respective fields.
              </span>
            </div>
            <div className="testimonial-client">
              <img src={Client3} alt="Client 3" />
              <span>Shreyansh Kothari</span>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-content">
              <span className="semicolor-content">"</span>
              <span className="testimonial-text">
                Great service by the Team of Finance. Their execution approach
                is amazing. Would like to hold with them in future!" Networking
                Groups
              </span>
            </div>
            <div className="testimonial-client">
              <img src={Client4} alt="Client 4" />
              <span>Abhishek Gotawala</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCards;
