import React, { useContext } from "react";
import "./AccountPayebleBanner.scss";
import AccountPayebleBannerImg from "../../../assets/images/reconciliation-banner.svg";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function AccountPayebleBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="account-payeble-banner-section">
      <div className="container">
        <div className="account-payeble-banner-grid">
          <div className="account-payeble-banner-img-wrapper">
            <div className="account-payeble-banner-img">
              <img
                src={AccountPayebleBannerImg}
                alt="AccountPayebleBannerImg"
              />
            </div>
          </div>

          <div className="virtual-banner-text">
            <h1>Reconciliation {state.country}</h1>
            <h2>
              At Finaccle we provide reconciliation solutions, and it can help
              you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Ensuring accurate financial records</li>
                <li>Detecting and resolving discrepancies</li>
                <li>Maintaining compliance with accounting standards</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h1>Account reconciliation</h1>
          <p>
            Account reconciliation is a crucial process in accounting that
            compares two sets of records to ensure accuracy and consistency.
          </p>
          <p>
            It confirms the general ledger's completeness and accuracy, helps
            explain differences between financial records, detects fraud, and
            ensures compliance with regulations.
          </p>
          <p>
            The process involves matching beginning balances, gathering data,
            analyzing it, and saving documents for review and audit.
          </p>
          <p>
            Automated tools like account reconciliation software streamline the
            process by providing seamless connectivity, standardized processes,
            and integrated documentation storage, enhancing efficiency and
            accuracy.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
