import React from "react";
import "./ProvideFinaccle.scss";
import Icon1 from "../../../assets/icons/Bookkeeping.svg";
import Icon2 from "../../../assets/icons/accounting-solution.png";
import Icon3 from "../../../assets/icons/cost-effective.png";
import Icon4 from "../../../assets/icons/user-expert.png";
import Icon5 from "../../../assets/icons/scalability.png";
import Icon6 from "../../../assets/icons/customer-service.png";
import Icon7 from "../../../assets/icons/cost-effective.png";
import Icon8 from "../../../assets/icons/global.png";
import Icon9 from "../../../assets/icons/certified.png";
import Icon10 from "../../../assets/icons/accounting-solution.png";
import Icon11 from "../../../assets/icons/handshake.png";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function ProvideFinaccle() {
  return (
    <div className="provide-finaccle-section">
      <div className="container">
        <h2> What we provide at Finaccle</h2>
        <div className="provide-finaccle-details-alignment">
          <div className="provide-finaccle-child-details">
            <div className="offshore-flex-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>Comprehensive Accounting Solutions with Finaccle</h4>
                <p>
                  Finaccle's offshore accounting services provide comprehensive
                  solutions for businesses outsourcing their accounting
                  functions.
                </p>
                <p>
                  Covering bookkeeping, financial reporting, tax compliance, and
                  more, Finaccle ensures all accounting needs are met
                  efficiently.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon3} alt="Icon1" />
                <h4>Cost-Effective Alternative Powered by Finaccle</h4>
                <p>
                  Finaccle's offshore accounting services offer a cost-effective
                  alternative to maintaining an in-house team.
                </p>
                <p>
                  Leveraging lower labor costs and favorable exchange rates,
                  Finaccle helps businesses achieve significant savings.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon4} alt="Icon1" />
                <h4>Access to Specialized Expertise with Finaccle</h4>
                <p>
                  Finaccle's offshore accounting service providers boast skilled
                  professionals in accounting, finance, and taxation.
                </p>
                <p>
                  With Finaccle, businesses gain access to specialized knowledge
                  and experience without the need for additional hiring or
                  training.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Scalability and Flexibility Tailored by Finaccle</h4>
                <p>
                  Finaccle's offshore accounting services are scalable and
                  flexible, catering to businesses of all sizes and industries.
                </p>
                <p>
                  Whether a small startup or a multinational corporation,
                  Finaccle tailors services to meet specific needs and growth
                  objectives.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>24/7 Accessibility and Support from Finaccle:</h4>
                <p>
                  Many offshore accounting service providers associated with
                  Finaccle offer round-the-clock accessibility and support.
                </p>
                <p>
                  This ensures businesses can access assistance promptly,
                  enhancing operational efficiency and peace of mind.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
            }}
          >
            <h3>Advantages</h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon7} alt="Icon1" />
                <h4>Reduced Overhead Costs with Finaccle:</h4>
                <p>
                  Finaccle's offshore accounting services help reduce overhead
                  costs associated with staffing, training, and infrastructure.
                </p>
                <p>
                  By offering predictable costs and enhanced financial control,
                  Finaccle empowers businesses to manage expenses efficiently.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Focus on Core Business Activities Supported by Finaccle</h4>
                <p>
                  Finaccle's offshore accounting services enable businesses to
                  focus on core activities and strategic initiatives.
                </p>
                <p>
                  By delegating accounting responsibilities to experts,
                  businesses can allocate resources efficiently and drive
                  growth.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon8} alt="Icon1" />
                <h4>
                  Global Compliance and Best Practices Upheld by Finaccle:
                </h4>
                <p>
                  Finaccle ensures compliance with global accounting standards,
                  regulations, and best practices.
                </p>
                <p>
                  Businesses mitigate the risk of penalties and legal issues by
                  leveraging Finaccle's expertise in international financial
                  reporting standards.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
              marginBottom: "60px",
            }}
          >
            <h3>Why Choose Offshore Accounting Services by Finaccle</h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon9} alt="Icon1" />
                <h4>Proven Track Record of Success with Finaccle</h4>
                <p>
                  Finaccle's offshore accounting service providers have a proven
                  track record of delivering high-quality services globally.
                </p>
                <p>
                  Trusted by numerous clients across industries, Finaccle
                  consistently demonstrates reliability, efficiency, and
                  professionalism.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon10} alt="Icon1" />
                <h4>
                  Customized Solutions Tailored to Your Needs with Finaccle
                </h4>
                <p>
                  Finaccle offers customized offshore accounting solutions
                  aligned with each client's unique requirements and objectives.
                </p>
                <p>
                  Whether basic bookkeeping or complex financial analysis,
                  Finaccle designs packages to align with specific business
                  goals.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon11} alt="Icon1" />
                <h4>Strategic Partnership for Long-Term Success by Finaccle</h4>
                <p>
                  Finaccle aims to establish long-term partnerships with
                  clients, becoming trusted advisors and collaborators.
                </p>
                <p>
                  By forging strategic relationships with Finaccle, businesses
                  gain a competitive edge, streamline operations, and achieve
                  sustainable growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
