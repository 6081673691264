import React from "react";
import "./PayableServices.scss";
import ServicesImg1 from "../../../assets/images/services-4.png";
import ServicesImg2 from "../../../assets/images/Cloud hosting-bro.svg";
import ServicesImg3 from "../../../assets/images/services-1.png";
import ServicesImg5 from "../../../assets/images/services-5.png";
import ServicesImg4 from "../../../assets/images/Experts-bro.svg";
import ServicesImg6 from "../../../assets/images/confidentiality-bro.svg";
import ServicesImg7 from "../../../assets/images/reduced-risk-bro.svg";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function PayableServices() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="payableServicesSection">
      <div className="container">
        <div className="payableServicesHeading">
          <h2>Benefits of Outsourcing Payroll Services</h2>
        </div>

        <div
          className="payableServicesDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Compliance and Accuracy</h3>
                <p>
                  Payroll service providers ensure compliance with tax laws and
                  regulations, reducing the risk of errors and penalties in
                  payroll tax filings and other compliance issues by
                  outsourcing.
                </p>
              </div>
            </AnimatedSection>
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg1} alt="ServicesImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg5} alt="ServicesImg5" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Time Efficiency</h3>
                <p>
                  Outsourcing payroll processing can significantly improve time
                  efficiency for businesses with complex structures or multiple
                  locations, allowing them to concentrate on core activities and
                  leave payroll tasks to experts.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Cost Savings</h3>
                <p>
                  Outsourcing payroll can significantly reduce costs for
                  businesses by reducing expenses related to salaries, benefits,
                  training, software, and infrastructure. It can be more
                  cost-effective than maintaining an in-house payroll
                  department.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg3} alt="ServicesImg3" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg4} alt="ServicesImg4" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Access to Expertise</h3>
                <p>
                  Payroll service providers possess specialized knowledge in
                  payroll processing, tax regulations, and technology, offering
                  insights, advice, and best practices to help businesses
                  streamline payroll processes and maintain compliance.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Enhanced Security</h3>
                <p>
                  Outsourcing payroll to service providers can improve data
                  security by implementing secure systems, encryption, and
                  compliance with data protection regulations, ensuring the
                  protection of sensitive employee information.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg6} alt="ServicesImg6" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg7} alt="ServicesImg7" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Risk Mitigation</h3>
                <p>
                  Outsourcing payroll can help reduce risks like internal fraud,
                  errors, and non-compliance by implementing security measures
                  and controls implemented by service providers.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Access to Advanced Technology</h3>
                <p>
                  Payroll service providers often utilize advanced payroll
                  software and technology platforms to streamline processes,
                  improve accuracy, and enhance reporting capabilities,
                  benefiting businesses without the need for internal
                  investment.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg2} alt="ServicesImg2" />
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
