import React from "react";
import "./PricingBanner.scss";
import PricingImg from "../../../assets/images/Pricing-plans-img.png";
export default function PricingBanner() {
  return (
    <div className="pricing-banner-section">
      <div className="pricing-banner-alignment">
        <div className="pricing-left">
          <h1>
            At FinAccle, we offer flexible pricing designed to fit your unique
            business needs. Choose from a range of payment plans that align with
            your financial dreams to ensure you get paid answers.
          </h1>

          <div className="enquiry-button">
            <button>Enquire Now</button>
          </div>
        </div>
        <div className="pricing-right">
          <img src={PricingImg} alt="PricingImg" />
        </div>
      </div>
    </div>
  );
}
