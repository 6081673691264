import React from "react";
import "./ProvideFinaccle.scss";
import Icon1 from "../../../assets/icons/Bookkeeping.svg";
import Icon2 from "../../../assets/icons/money-bag.png";
import Icon3 from "../../../assets/icons/Laptop Code.svg";
import Icon4 from "../../../assets/icons/user-expert.png";
import Icon5 from "../../../assets/icons/step-4.svg";
import Icon6 from "../../../assets/icons/scalability.png";
import Icon7 from "../../../assets/icons/verified.png";
import Icon8 from "../../../assets/icons/user-friendly.png";
import Icon9 from "../../../assets/icons/customer-service.png";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function ProvideFinaccle() {
  return (
    <div className="provide-finaccle-section">
      <div className="container">
        <h2> What we provide at Finaccle</h2>
        <div className="provide-finaccle-details-alignment">
          <div className="provide-finaccle-child-details">
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Comprehensive Bookkeeping Services with Finaccle</h4>
                <p>
                  Finaccle offers comprehensive bookkeeping services, including
                  data entry, transaction categorization, and account
                  reconciliation.
                </p>
                <p>
                  Benefit from Finaccle's meticulous management of your
                  financial records to ensure accuracy and compliance with
                  relevant regulations.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>
                  Efficient Accounts Payable and Receivable Management with
                  Finaccle
                </h4>
                <p>
                  Finaccle streamlines your accounts payable and receivable
                  processes, ensuring timely payments to vendors and efficient
                  collections from clients.
                </p>
                <p>
                  Finaccle streamlines your accounts payable and receivable
                  processes, ensuring timely payments to vendors and efficient
                  collections from clients.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon3} alt="Icon1" />
                <h4>Customized Reporting and Analysis with Finaccle</h4>
                <p>
                  Generate customized financial reports and dashboards tailored
                  to your preferences with Finaccle's powerful analytical tools.
                </p>
                <p>
                  Gain valuable insights into your financial performance,
                  identify trends, and make informed decisions with Finaccle's
                  comprehensive analysis.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
            }}
          >
            <h3>Advantages</h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Cost Savings with Finaccle</h4>
                <p>
                  Outsourcing your accounting tasks to Finaccle helps you save
                  on costs associated with maintaining an in-house accounting
                  department.
                </p>
                <p>
                  Benefit from Finaccle's competitive pricing, ensuring
                  high-quality services at a fraction of the cost for businesses
                  of all sizes.{" "}
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon4} alt="Icon1" />
                <h4>Expertise and Reliability of Finaccle</h4>
                <p>
                  Leverage the expertise of Finaccle's seasoned accounting
                  professionals, well-versed in international best practices.
                </p>
                <p>
                  Ensure reliability and accuracy in your financial records with
                  Finaccle, reducing the risk of errors and discrepancies.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>Scalability and Flexibility with Finaccle</h4>
                <p>
                  Finaccle's outsourcing solution is scalable and flexible,
                  allowing you to adjust the level of service according to your
                  business needs.
                </p>
                <p>
                  Whether you're a startup or an enterprise, Finaccle can adapt
                  to accommodate your evolving requirements seamlessly.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
              marginBottom: "60px",
            }}
          >
            <h3>Why Choose Finaccle </h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon7} alt="Icon1" />
                <h4>Proven Track Record of Finaccle</h4>
                <p>
                  Finaccle has a proven track record of delivering reliable and
                  efficient accounting solutions to businesses globally.
                </p>
                <p>
                  Trusted by numerous clients across industries, Finaccle
                  consistently exceeds expectations and delivers tangible
                  results
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon8} alt="Icon1" />
                <h4>User-Friendly Interface of Finaccle </h4>
                <p>
                  Finaccle features an intuitive user interface designed for
                  ease of use and efficiency.
                </p>
                <p>
                  With minimal training required, you can quickly onboard your
                  team and leverage the benefits of Finaccle's outsourcing
                  solution.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon9} alt="Icon1" />
                <h4>Dedicated Customer Support from Finaccle</h4>
                <p>
                  Finaccle prioritizes customer satisfaction and offers
                  dedicated support to address any queries or concerns.
                </p>
                <p>
                  Access Finaccle's responsive customer support team
                  round-the-clock for assistance with any issues or technical
                  challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
