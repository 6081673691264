import React from "react";
import "./CatchServices.scss";

export default function CatchServices() {
  return (
    <div className="container">
      <div className="monthly-services-section">
        <h3>Finaccle offers you...</h3>

        <div className="monthly-services-details-alignment">
          <div className="monthly-services-grid">
            <div className="monthly-services-grid-item">
              <h4>Enhanced Financial Clarity</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Monthly bookkeeping provides a clear snapshot of your business
                finances, helping you make informed decisions and identify areas
                for improvement.
              </p>
            </div>
            <div className="monthly-services-grid-item">
              <h4>Improved Cash Flow Management </h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                By tracking income and expenses regularly, you can optimize cash
                flow, ensuring that your business has enough funds to cover
                expenses and invest in growth opportunities.
              </p>
            </div>
            <div className="monthly-services-grid-item">
              <h4>Compliance Assurance</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Monthly bookkeeping ensures that your financial records are
                accurate and up-to-date, helping you meet regulatory
                requirements and avoid potential penalties or fines.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
