import React from "react";
import "./BookCallBannerButton.scss";
import CallIcon from "../../assets/icons/book-call.svg";

function BookCallBannerButton() {
  const handleClickOpen = () => {
    window.open("https://calendly.com/info-finaccleglobal/30min", "_blank");
  };

  return (
    <div className="book-call-banner-main-wrapper">
      <div className="icon-text-wrapper">
        <img src={CallIcon} alt="" />
        <div className="book-text">
          <span>Looking for Expert advice? </span>
        </div>
      </div>
      <div className="book-call-btm-wrapper">
        <button onClick={handleClickOpen}>Book a call now</button>
      </div>
    </div>
  );
}

export default BookCallBannerButton;
