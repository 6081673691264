import React from "react";
import "./WhyChooseUs.scss";
import WhyChooseImg from "../../../assets/images/why-choose-img.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";

export default function WhyChooseUs() {
  return (
    <div className="whyChooseUs-section">
      <h2>Why Choose Us?</h2>

      <div className="whyChooseUs-details-alignment">
        <div className="whyChooseUs-grid">
          <div className="whyChooseUs-img">
            <AnimatedSection animationType="zoom-in-right" duration="900">
              <img src={WhyChooseImg} alt="WhyChooseImg" />
            </AnimatedSection>
          </div>
          <AnimatedSection animationType="zoom-in-left" duration="900">
            <div className="whyChooseUs-right-side-alignment">
              <div className="list-name-alignment">
                <h6>Communication</h6>
                <p>
                  Clear and powerful conversation in English at each touchpoint.
                </p>
              </div>
              <div className="list-name-alignment">
                <h6>Automation</h6>
                <p>Leveraging generation for optimized offerings globally.</p>
              </div>
              <div className="list-name-alignment">
                <h6>Qualified Team</h6>
                <p>
                  Chartered Accountants and Finance experts devoted to
                  first-rate carrier transport.
                </p>
              </div>
              <div className="list-name-alignment">
                <h6>Confidentiality</h6>
                <p>
                  Your information is stable with our strict confidentiality
                  measures.
                </p>
              </div>
              <div className="list-name-alignment">
                <h6>Bespoke Consultation</h6>
                <p>
                  Unlimited professional advice via cell phone and e-mail on
                  every occasion you need it.
                </p>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </div>
    </div>
  );
}
