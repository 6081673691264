import "./Home.scss";
import React, { useContext, useRef, useState } from "react";
import USPImage from "../../assets/images/usp-vector.svg";
import PhoneHorn from "../../assets/icons/phone-horn.svg";
import ITax from "../../assets/icons/iTax.svg";
import Coins from "../../assets/icons/coins.svg";
import BookKeeping from "../../assets/icons/book-keeping.svg";
import SkilledProfessional from "../../assets/icons/skilled-professional.svg";
import Union from "../../assets/icons/technology.svg";
import SecurityLock from "../../assets/icons/security-lock.svg";
import HomeBannerVector from "../../assets/images/home-banner-vector.svg";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import OurProcess from "../../components/OurProcess/OurProcess";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import AnimatedSection from "../../components/Animation/AnimatedSection";
import { useNavigate } from "react-router-dom";
import BookCallBannerButton from "../../components/BookCallBannerButton/BookCallBannerButton";
import AllServices from "../../components/AllServices/AllServices";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";
import { GlobalContext } from "../../store/GlobalState";

function Home() {
  const navigate = useNavigate();
  const { state } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    services: "Accounts Outsourcing",
    message: "",
  });

  const allServices = [
    "Accounts Outsourcing",
    "Accounts Payable",
    "Accounts Receivable",
    "Billing And Invoicing",
    "Budgeting And Forecasting",
    "Catch Up Accounting",
    "CFM",
    "Customer Order Processing",
    "Financial Reporting",
    "Monthly Bookkeeping",
    "Net Suite Accounting",
    "Off Shore Accounting",
    "Payroll",
    "Quick Books Accounting",
    "Reconciliation",
    "Retro Accounting",
    "Virtual CFO",
    "Zoho Books Accounting",
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_e3zyjeq", "template_0pg11w9", form.current, {
        publicKey: "CeXFqwAv_htf4Jph_",
      })
      .then(
        () => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            plan: "Full-time Resource",
            message: "",
          });
          handleClose();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const ourFiveServicesData = [
    {
      serviceTitle: "Virtual CFO",
      routeName: `/virtual-cfo/${state.country}`,
    },
    {
      serviceTitle: "Accounts Payable",
      routeName: `/payable/${state.country}`,
    },
    {
      serviceTitle: "Accounts Receivable",
      routeName: `/receivable/${state.country}`,
    },
    {
      serviceTitle: "Zoho Books Accounting",
      routeName: `/zoho-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Net Suite Accounting",
      routeName: `/net-suite-accounting/${state.country}`,
    },
  ];

  const servedToData = [
    {
      serviceTitle: "Motels",
    },
    {
      serviceTitle: "Clothing Store",
    },
    {
      serviceTitle: "CPAs",
    },
    {
      serviceTitle: "Marketing Agencies",
    },
    {
      serviceTitle: "Restaurants",
    },
    {
      serviceTitle: "Real Estate",
    },
    {
      serviceTitle: "Recycling Companies",
    },
    {
      serviceTitle: "IT Developers",
    },
    {
      serviceTitle: "Startups",
    },
  ];

  return (
    <>
      <div className="main-home-wrapper">
        <div className="homepage-content-wrapper">
          <div className="home-banner-wrapper">
            <div className="banner-text-btn-wrapper">
              <div className="banner-text-wrapper">
                <h1>Welcome To Finaccle</h1>
                <p>
                  One stop solution for
                  <br /> all your Bookkeeping needs
                </p>
              </div>
              <div className="banner-btn-wrapper">
                <button className="banner-btn" onClick={handleClickOpen}>
                  GET FREE QUOTE TODAY!
                </button>
                <button
                  className="banner-btn"
                  onClick={() => {
                    window.open(
                      "https://calendly.com/info-finaccleglobal/30min",
                      "_blank"
                    );
                  }}
                >
                  BOOK A FREE CALL NOW
                </button>
              </div>
            </div>
            <div className="home-banner-img-wrapper">
              <img src={HomeBannerVector} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="served-to-wrapper">
              <div className="our-services-title">Our Services</div>
              <AnimatedSection animationType="fade-up" duration="1000">
                <div className="served-to-card-wrapper">
                  {ourFiveServicesData?.slice(0, 3).map((data, index) => {
                    return (
                      <div
                        className="served-to-card-content"
                        key={index}
                        style={{
                          width: "246px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(data.routeName)}
                      >
                        <div
                          className="card-title"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          {data.serviceTitle}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AnimatedSection>

              <AnimatedSection animationType="fade-up" duration="1000">
                <div className="served-to-card-wrapper">
                  {ourFiveServicesData?.slice(3).map((data, index) => {
                    return (
                      <div
                        className="served-to-card-content"
                        key={index}
                        style={{
                          width: "246px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(data.routeName)}
                      >
                        <div
                          className="card-title"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          {data.serviceTitle}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AnimatedSection>
            </div>
          </div>

          <div className="explore-more-button-wrapper">
            <BookCallBannerButton />
          </div>

          <div className="container">
            <div className="our-usp-wrapper">
              <div className="usp-title-wrapper">
                <div className="our-services-title">Our USP</div>
                <div className="usp-description">
                  Discover what sets us apart
                </div>
              </div>
              <div className="our-usp-details-wrapper">
                <AnimatedSection animationType="zoom-in-down" duration="1000">
                  <div className="usp-image-wrapper">
                    <img src={USPImage} alt="Our USP" />
                  </div>
                </AnimatedSection>

                <AnimatedSection animationType="zoom-in-up" duration="1000">
                  <div className="usp-card-content">
                    <div className="card-body-content">
                      <span>
                        Automation facilitates maximum quality with minimum
                        effort.
                      </span>
                    </div>
                    <div className="card-body-content">
                      <span>Simplified Accounting Outsourcing Services</span>
                    </div>
                    <div className="card-body-content">
                      <span>Competitive and Customizable Pricing.</span>
                    </div>
                    <div className="card-body-content">
                      <span>
                        Experienced and Skilled Professional Resources
                      </span>
                    </div>
                  </div>
                </AnimatedSection>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="hire-us-wrapper">
              <div className="usp-title-wrapper">
                <div className="our-services-title">Why hire us?</div>
                <div className="usp-description">
                  Experience-driven Solutions for Your Success
                </div>
              </div>
              <AnimatedSection animationType="zoom-in-up" duration="1000">
                <div className="our-services-card-wrapper">
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={PhoneHorn} alt="" />
                    </div>
                    <div className="card-title">
                      Customized to meet business needs
                    </div>
                    <div className="card-body-content">
                      Tailor-made services to meet the unique requirements of
                      your company.
                    </div>
                  </div>
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={ITax} alt="" />
                    </div>
                    <div className="card-title">Competitive Advantage</div>
                    <div className="card-body-content">
                      Bring scalability to your business providing a market
                      advantage.
                    </div>
                  </div>
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={Coins} alt="" />
                    </div>
                    <div className="card-title">Competitive Pricing</div>
                    <div className="card-body-content">
                      Competitive rates in comparison to all other alternatives.
                    </div>
                  </div>
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={BookKeeping} alt="" />
                    </div>
                    <div className="card-title">Dedicated Assistance</div>
                    <div className="card-body-content">
                      Committed support by our experienced professionals for
                      your assistance.
                    </div>
                  </div>
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={SkilledProfessional} alt="" />
                    </div>
                    <div className="card-title">Skilled Professional</div>
                    <div className="card-body-content">
                      Tailor-made services to meet the unique requirements of
                      your company.
                    </div>
                  </div>
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={Union} alt="" />
                    </div>
                    <div className="card-title">Technology-Oriented</div>
                    <div className="card-body-content">
                      Utilizing technology to its greatest potential to serve
                      customers worldwide.
                    </div>
                  </div>
                  <div className="services-card-content">
                    <div className="card-image">
                      <img src={SecurityLock} alt="" />
                    </div>
                    <div className="card-title">Security And Privacy</div>
                    <div className="card-body-content">
                      Solid security measures, effective internal controls, and
                      a privacy policy for client confidentiality.
                    </div>
                  </div>
                </div>
              </AnimatedSection>
            </div>
          </div>

          <div className="container">
            <div className="served-to-wrapper">
              <div className="our-services-title">Served to</div>
              <AnimatedSection animationType="fade-up" duration="1000">
                <div className="served-to-card-wrapper">
                  {servedToData?.slice(0, 3).map((data, index) => {
                    return (
                      <div className="served-to-card-content" key={index}>
                        <div className="card-title">{data.serviceTitle}</div>
                      </div>
                    );
                  })}
                </div>
              </AnimatedSection>

              <AnimatedSection animationType="fade-up" duration="1000">
                <div
                  className="served-to-card-wrapper"
                  style={{ marginTop: "30px" }}
                >
                  {servedToData?.slice(3, 6).map((data, index) => {
                    return (
                      <div className="served-to-card-content" key={index}>
                        <div className="card-title">{data.serviceTitle}</div>
                      </div>
                    );
                  })}
                </div>
              </AnimatedSection>

              <AnimatedSection animationType="fade-up" duration="1000">
                <div
                  className="served-to-card-wrapper"
                  style={{ marginTop: "30px" }}
                >
                  {servedToData?.slice(6).map((data, index) => {
                    return (
                      <div className="served-to-card-content" key={index}>
                        <div className="card-title">{data.serviceTitle}</div>
                      </div>
                    );
                  })}
                </div>
              </AnimatedSection>
            </div>
          </div>

          <div className="explore-more-button-wrapper">
            <BookCallBannerButton />
          </div>

          <div className="container">
            <OurProcess />
          </div>

          <div className="container">
            <TestimonialCards />
          </div>

          <div className="container" style={{ marginBottom: "60px" }}>
            <LatestBlog />
          </div>

          <AllServices />
        </div>
      </div>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="contact-us-modal"
          className="custom-modal-wrapper"
          sx={{
            "& .MuiDialog-paper": {
              width: "650px",
            },
          }}
        >
          <form ref={form} onSubmit={handleSubmit}>
            <DialogTitle
              id="contact-us-modal"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Contact Us</span>
              <span
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </span>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      value={formData.firstName}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={formData.lastName}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>Phone</label>
                    <input
                      type="text"
                      name="phone"
                      onChange={handleChange}
                      value={formData.phone}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1,1fr)",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <label>Select your service</label>
                  <select
                    name="services"
                    onChange={handleChange}
                    value={formData.services}
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "4px",
                      fontFamily: "Anek Tamil",
                      height: "40px",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    {allServices?.map((service, index) => {
                      return (
                        <option value={service} key={index}>
                          {service}
                        </option>
                      );
                    })}
                  </select>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1,1fr)",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <label>Message</label>
                  <textarea
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                    rows="10"
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "4px",
                      fontFamily: "Anek Tamil",
                      resize: "none",
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type="submit" autoFocus>
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
}

export default Home;
