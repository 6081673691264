import "./Header.scss";
import React, { useContext, useRef, useState } from "react";
import FinaccleLogo from "../../assets/logos/Finaccle-Logo.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GlobalContext } from "../../store/GlobalState";
import UKFlag from "../../assets/icons/uk-flag.png";
import AustraliaFlag from "../../assets/icons/australia.png";
import USFlag from "../../assets/icons/us.png";
import SingaporeFlag from "../../assets/icons/singapore.png";
import USRounded from "../../assets/images/usa-rounded.png";
import UKRounded from "../../assets/images/uk-rounded.png";
import AustraliaRounded from "../../assets/images/australia-rounded.png";
import SingaporeRounded from "../../assets/images/singapore-rounded.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  BottomNavigation,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";
import MenuIcon from "@mui/icons-material/Menu";
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useContext(GlobalContext);
  const { state } = useContext(GlobalContext);
  const isMobile = useMediaQuery("(max-width:1024px)");
  const [bookKeepingAnchorEl, setBookKeepingAnchorEl] = useState(null);
  const [advisoryAnchorEl, setAdvisoryAnchorEl] = useState(null);
  const [globleAnchorEl, setGlobeAnchorEl] = useState(null);
  const [countryImage, setCountryImage] = useState(UKRounded);
  const openBookKeeping = Boolean(bookKeepingAnchorEl);
  const openAdvisory = Boolean(advisoryAnchorEl);
  const openGlobe = Boolean(globleAnchorEl);
  const [open, setOpen] = useState(false);
  const form = useRef();
  const [advisoryMobiledropdown, setAdvisoryMobiledropdown] = useState(false);
  const [mobiledropdown, setmobiledropdown] = useState(false);
  const [mobileViewSidebra, setmobileViewSidebar] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    services: "Accounts Outsourcing",
    message: "",
  });

  const allServices = [
    "Accounts Outsourcing",
    "Accounts Payable",
    "Accounts Receivable",
    "Billing And Invoicing",
    "Budgeting And Forecasting",
    "Catch Up Accounting",
    "CFM",
    "Customer Order Processing",
    "Financial Reporting",
    "Monthly Bookkeeping",
    "Net Suite Accounting",
    "Off Shore Accounting",
    "Payroll",
    "Quick Books Accounting",
    "Reconciliation",
    "Retro Accounting",
    "Virtual CFO",
    "Zoho Books Accounting",
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountryChange = (countryName) => {
    console.log("@@@@ location.pathname", location);
    if (
      location.pathname === "/blogs" ||
      location.pathname === "/about-us" ||
      location.pathname === "/pricing" ||
      location.pathname === "/" ||
      location.state === "blogDetails"
    ) {
      dispatch({ type: "SET_VALUE", payload: countryName });
      setGlobeAnchorEl(null);
      return;
    } else {
      navigate(`${location.pathname.split("/")[1]}/${countryName}`);
      dispatch({ type: "SET_VALUE", payload: countryName });
      setGlobeAnchorEl(null);
    }
  };

  const handleBookKeepingClick = (event) => {
    setBookKeepingAnchorEl(event.currentTarget);
  };

  const handleBookingKeepingClose = (routeName) => {
    navigate(routeName);
    setBookKeepingAnchorEl(null);
  };

  const handleAdvisoryClick = (event) => {
    setAdvisoryAnchorEl(event.currentTarget);
  };

  const handleGlobeClick = (event) => {
    setGlobeAnchorEl(event.currentTarget);
  };

  const handleAdvisoryClose = (routeName) => {
    navigate(routeName);
    setAdvisoryAnchorEl(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_e3zyjeq", "template_0pg11w9", form.current, {
        publicKey: "CeXFqwAv_htf4Jph_",
      })
      .then(
        () => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            plan: "Full-time Resource",
            message: "",
          });
          handleClose();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const handleMobileNavigation = (routeName) => {
    navigate(routeName);
    setmobileViewSidebar(false);
  };

  return (
    <>
      <div className="main-header-wrapper">
        <div className="container">
          <div className="main-header-flex">
            <div
              className="logo-wrapper"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={FinaccleLogo} alt="Finaccle" className="img-logo" />
            </div>
            {!isMobile ? (
              <div className="main-links-wrapper">
                <NavLink
                  to="/about-us"
                  className="link-text"
                  activeclassname="active"
                >
                  About Us
                </NavLink>
                <div className="menu-wrapper">
                  <div
                    id="advisory-menu-button"
                    className="menu-button"
                    aria-controls={openAdvisory ? "advisory-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openAdvisory ? "true" : undefined}
                    onClick={handleAdvisoryClick}
                  >
                    <span>Advisory</span>

                    <KeyboardArrowDownIcon
                      sx={{
                        color: "#CDCDCD",
                      }}
                    />
                  </div>
                  <Menu
                    id="advisory-menu"
                    anchorEl={advisoryAnchorEl}
                    open={openAdvisory}
                    onClose={() => setAdvisoryAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "advisory-menu-button",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        boxShadow:
                          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 2px rgba(0,0,0,0.12)",
                      },
                      "& .MuiMenuItem-root": {
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        handleAdvisoryClose(`payroll/${state.country}`)
                      }
                    >
                      Payroll
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleAdvisoryClose(
                          `financial-reporting/${state.country}`
                        )
                      }
                    >
                      Financial Reporting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleAdvisoryClose(
                          `budgeting-and-forecasting/${state.country}`
                        )
                      }
                    >
                      Budgeting And Forecasting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleAdvisoryClose(`cfm/${state.country}`)
                      }
                    >
                      CFM
                    </MenuItem>
                  </Menu>
                </div>
                <NavLink
                  to={`/virtual-cfo/${state.country}`}
                  className="link-text"
                  activeclassname="active"
                >
                  Virtual CFO
                </NavLink>
                <div className="menu-wrapper">
                  <div
                    id="book-keeping-menu-button"
                    className="menu-button"
                    aria-controls={
                      openBookKeeping ? "book-keeping-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openBookKeeping ? "true" : undefined}
                    onClick={handleBookKeepingClick}
                  >
                    <span>Bookkeeping</span>

                    <KeyboardArrowDownIcon
                      sx={{
                        color: "#CDCDCD",
                      }}
                    />
                  </div>
                  <Menu
                    id="book-keeping-menu"
                    className="book-keeping-menu-item"
                    anchorEl={bookKeepingAnchorEl}
                    open={openBookKeeping}
                    onClose={() => setBookKeepingAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "book-keeping-menu-button",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        boxShadow:
                          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 2px rgba(0,0,0,0.12)",
                      },
                      "& .MuiMenuItem-root": {
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(`payable/${state.country}`)
                      }
                    >
                      Accounts Payable
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(`receivable/${state.country}`)
                      }
                    >
                      Accounts Receivable
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `billing-and-invoicing/${state.country}`
                        )
                      }
                    >
                      Billing And Invoicing
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `reconciliation/${state.country}`
                        )
                      }
                    >
                      Reconciliation
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `customer-order-processing/${state.country}`
                        )
                      }
                    >
                      Customer Order Processing
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `account-outsourcing/${state.country}`
                        )
                      }
                    >
                      Accounts Outsourcing
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `off-shore-accounting/${state.country}`
                        )
                      }
                    >
                      Off Shore Accounting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `net-suite-accounting/${state.country}`
                        )
                      }
                    >
                      Net Suite Accounting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `zoho-books-accounting/${state.country}`
                        )
                      }
                    >
                      Zoho Books Accounting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `quick-books-accounting/${state.country}`
                        )
                      }
                    >
                      Quick Books Accounting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(`xero/${state.country}`)
                      }
                    >
                      Xero Accounting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `catch-up-accounting/${state.country}`
                        )
                      }
                    >
                      Catch Up Accounting
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `monthly-book-keeping/${state.country}`
                        )
                      }
                    >
                      Monthly Bookkeeping
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleBookingKeepingClose(
                          `retro-accounting/${state.country}`
                        )
                      }
                    >
                      Retro Accounting
                    </MenuItem>
                  </Menu>
                </div>
                <NavLink
                  to="/blogs"
                  className="link-text"
                  activeclassname="active"
                >
                  Blogs
                </NavLink>
                <NavLink
                  to="/pricing"
                  className="link-text"
                  activeclassname="active"
                >
                  Pricing
                </NavLink>
                <div>
                  <button
                    className="enquire-now-button"
                    onClick={handleClickOpen}
                  >
                    Enquire Now
                  </button>
                </div>
                <div className="menu-wrapper">
                  <div
                    id="globe-menu-button"
                    className="menu-button"
                    aria-controls={openGlobe ? "globe-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openGlobe ? "true" : undefined}
                    onClick={handleGlobeClick}
                  >
                    <img
                      src={countryImage}
                      alt=""
                      style={{
                        height: "30px",
                      }}
                    />
                    <KeyboardArrowDownIcon
                      sx={{
                        color: "#CDCDCD",
                      }}
                    />
                  </div>
                  <Menu
                    id="globe-menu"
                    className="globe-menu-item"
                    anchorEl={globleAnchorEl}
                    open={openGlobe}
                    onClose={() => setGlobeAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "globe-menu-button",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        boxShadow:
                          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 2px rgba(0,0,0,0.12)",
                      },
                      "& .MuiMenuItem-root": {
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("UK");
                        setCountryImage(UKRounded);
                      }}
                    >
                      <img
                        src={UKFlag}
                        alt="UK"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>UK</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("Australia");
                        setCountryImage(AustraliaRounded);
                      }}
                    >
                      <img
                        src={AustraliaFlag}
                        alt="Australia"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>Australia</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("Singapore");
                        setCountryImage(SingaporeRounded);
                      }}
                    >
                      <img
                        src={SingaporeFlag}
                        alt="Singapore"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>Singapore</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("US");
                        setCountryImage(USRounded);
                      }}
                    >
                      <img
                        src={USFlag}
                        alt="US"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>US</span>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            ) : (
              <div className="mobile-menu-icon-wrapper">
                <div className="menu-wrapper">
                  <div
                    id="globe-menu-button"
                    className="menu-button"
                    aria-controls={openGlobe ? "globe-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openGlobe ? "true" : undefined}
                    onClick={handleGlobeClick}
                  >
                    <img
                      src={countryImage}
                      alt=""
                      style={{
                        height: "30px",
                      }}
                    />
                    <KeyboardArrowDownIcon
                      sx={{
                        color: "#CDCDCD",
                      }}
                    />
                  </div>
                  <Menu
                    id="globe-menu"
                    className="globe-menu-item"
                    anchorEl={globleAnchorEl}
                    open={openGlobe}
                    onClose={() => setGlobeAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "globe-menu-button",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        boxShadow:
                          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 2px rgba(0,0,0,0.12)",
                        // left: "200px !important",
                      },
                      "& .MuiMenuItem-root": {
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#000",
                        fontWeight: "400",
                        fontSize: "16px",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("UK");
                        setCountryImage(UKRounded);
                      }}
                    >
                      <img
                        src={UKFlag}
                        alt="UK"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>UK</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("Australia");
                        setCountryImage(AustraliaRounded);
                      }}
                    >
                      <img
                        src={AustraliaFlag}
                        alt="Australia"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>Australia</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("Singapore");
                        setCountryImage(SingaporeRounded);
                      }}
                    >
                      <img
                        src={SingaporeFlag}
                        alt="Singapore"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>Singapore</span>
                    </MenuItem>
                    <MenuItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "10px",
                      }}
                      onClick={() => {
                        handleCountryChange("US");
                        setCountryImage(USRounded);
                      }}
                    >
                      <img
                        src={USFlag}
                        alt="US"
                        style={{
                          height: "34px",
                        }}
                      />
                      <span>US</span>
                    </MenuItem>
                  </Menu>
                </div>
                <MenuIcon
                  sx={{
                    fontSize: "32px",
                  }}
                  onClick={() => setmobileViewSidebar(true)}
                />
              </div>
            )}
          </div>
        </div>

        {mobileViewSidebra && <div className="mobile-view-wrapper"></div>}

        <div
          className={
            mobileViewSidebra
              ? "mobile-view-sidebar open-sidebar"
              : "mobile-view-sidebar open-sidebar close-sidebar"
          }
        >
          <div className="mobile-top-heading">
            <img src={FinaccleLogo} alt="FinaccleLogo" />

            <div
              className="close-icon-alignment"
              onClick={() => setmobileViewSidebar(false)}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="mobile-view-sidebar-details">
            <div className="mobile-view-sidebar-all-details">
              <div className="mobile-view-menu">
                <a onClick={() => handleMobileNavigation(`about-us`)}>
                  About Us
                </a>
              </div>
              <div className="mobile-view-menu">
                <a
                  onClick={() =>
                    setAdvisoryMobiledropdown(!advisoryMobiledropdown)
                  }
                >
                  Advisory
                  <div
                    onClick={() =>
                      setAdvisoryMobiledropdown(!advisoryMobiledropdown)
                    }
                  >
                    <ArrowDropDownIcon />
                  </div>
                </a>

                <div
                  className={
                    advisoryMobiledropdown
                      ? "mobile-view-child-menu open-mobile-view-dropdown"
                      : "mobile-view-child-menu hidden-mobile-view-dropdown"
                  }
                >
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(`payroll/${state.country}`)
                      }
                    >
                      Payroll
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `financial-reporting/${state.country}`
                        )
                      }
                    >
                      Financial Reporting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `budgeting-and-forecasting/${state.country}`
                        )
                      }
                    >
                      Budgeting And Forecasting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(`cfm/${state.country}`)
                      }
                    >
                      CFM
                    </a>
                  </div>
                </div>
              </div>
              <div className="mobile-view-menu">
                <a
                  onClick={() =>
                    handleMobileNavigation(`virtual-cfo/${state.country}`)
                  }
                >
                  Virtual CFO
                </a>
              </div>
              <div className="mobile-view-menu">
                <a onClick={() => setmobiledropdown(!mobiledropdown)}>
                  Bookkeeping
                  <div onClick={() => setmobiledropdown(!mobiledropdown)}>
                    <ArrowDropDownIcon />
                  </div>
                </a>

                <div
                  className={
                    mobiledropdown
                      ? "mobile-view-child-menu open-mobile-view-dropdown "
                      : "mobile-view-child-menu hidden-mobile-view-dropdown"
                  }
                >
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(`payable/${state.country}`)
                      }
                    >
                      Accounts Payable
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(`receivable/${state.country}`)
                      }
                    >
                      Accounts Receivable
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `billing-and-invoicing/${state.country}`
                        )
                      }
                    >
                      Billing And Invoicing
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `reconciliation/${state.country}`
                        )
                      }
                    >
                      Reconciliation
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `customer-order-processing/${state.country}`
                        )
                      }
                    >
                      Customer Order Processing
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `account-outsourcing/${state.country}`
                        )
                      }
                    >
                      Accounts Outsourcing
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `off-shore-accounting/${state.country}`
                        )
                      }
                    >
                      Off Shore Accounting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `net-suite-accounting/${state.country}`
                        )
                      }
                    >
                      Net Suite Accounting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `zoho-books-accounting/${state.country}`
                        )
                      }
                    >
                      Zoho Books Accounting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `quick-books-accounting/${state.country}`
                        )
                      }
                    >
                      Quick Books Accounting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(`xero/${state.country}`)
                      }
                    >
                      Xero Accounting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `catch-up-accounting/${state.country}`
                        )
                      }
                    >
                      Catch Up Accounting
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `monthly-book-keeping/${state.country}`
                        )
                      }
                    >
                      Monthly Bookkeeping
                    </a>
                  </div>
                  <div className="mobile-view-child-menu-name">
                    <a
                      onClick={() =>
                        handleMobileNavigation(
                          `retro-accounting/${state.country}`
                        )
                      }
                    >
                      Retro Accounting
                    </a>
                  </div>
                </div>
              </div>
              <div className="mobile-view-menu">
                <a onClick={() => handleMobileNavigation(`blogs`)}>Blogs</a>
              </div>
              <div className="mobile-view-menu">
                <a onClick={() => handleMobileNavigation(`pricing`)}>Pricing</a>
              </div>
            </div>

            <div className="enquiry-now-button">
              <button
                onClick={() => {
                  setmobileViewSidebar(false);
                  handleClickOpen();
                }}
              >
                Enquiry Now
              </button>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="contact-us-modal"
          className="custom-modal-wrapper"
          sx={{
            "& .MuiDialog-paper": {
              width: "650px",
            },
          }}
        >
          <form ref={form} onSubmit={handleSubmit}>
            <DialogTitle
              id="contact-us-modal"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>Contact Us</span>
              <span
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </span>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      value={formData.firstName}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      value={formData.lastName}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      gap: "5px",
                      width: "100%",
                    }}
                  >
                    <label>Phone</label>
                    <input
                      type="text"
                      name="phone"
                      onChange={handleChange}
                      value={formData.phone}
                      style={{
                        width: "100%",
                        border: "1px solid #c4c4c4",
                        borderRadius: "4px",
                        fontFamily: "Anek Tamil",
                        height: "34px",
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1,1fr)",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <label>Select your service</label>
                  <select
                    name="services"
                    onChange={handleChange}
                    value={formData.services}
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "4px",
                      fontFamily: "Anek Tamil",
                      height: "40px",
                      fontSize: "14px",
                      padding: "10px",
                    }}
                  >
                    {allServices?.map((service, index) => {
                      return (
                        <option value={service} key={index}>
                          {service}
                        </option>
                      );
                    })}
                  </select>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(1,1fr)",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <label>Message</label>
                  <textarea
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                    rows="10"
                    style={{
                      width: "100%",
                      border: "1px solid #c4c4c4",
                      borderRadius: "4px",
                      fontFamily: "Anek Tamil",
                      resize: "none",
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type="submit" autoFocus>
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
}

export default Header;
