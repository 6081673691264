import React, { useContext } from "react";
import "./AccountPayebleBanner.scss";
import AccountPayebleBannerImg from "../../../assets/images/order-banner.svg";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function AccountPayebleBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="account-payeble-banner-section">
      <div className="container">
        <div className="account-payeble-banner-grid">
          <div className="account-payeble-banner-img-wrapper">
            <div className="account-payeble-banner-img">
              <img
                src={AccountPayebleBannerImg}
                alt="AccountPayebleBannerImg"
              />
            </div>
          </div>

          <div className="virtual-banner-text">
            <h1>Customer Order Processing {state.country}</h1>
            <h2>
              At Finaccle we provide customer order processing solutions, and it
              can help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Streamlining order management</li>
                <li>Enhancing customer satisfaction</li>
                <li>Improving order accuracy and fulfillment</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="hiringVirtualCfoHeading"
          style={{
            margin: "90px 0",
          }}
        >
          <h1>Customer Order Processing</h1>
          <p>
            Customer order processing involves managing customer orders from
            receipt to delivery, ensuring smooth and efficient handling,
            enhancing satisfaction and loyalty.
          </p>
        </div>
      </div>
      <BookCallBannerButton />
    </div>
  );
}
