import React from "react";
import "./ProvideFinaccle.scss";
import Icon1 from "../../../assets/icons/Bookkeeping.svg";
import Icon2 from "../../../assets/icons/money-bag.png";
import Icon3 from "../../../assets/icons/Laptop Code.svg";
import Icon4 from "../../../assets/icons/certified.png";
import Icon5 from "../../../assets/icons/Union.svg";
import Icon6 from "../../../assets/icons/Management.svg";
import Icon7 from "../../../assets/icons/track.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function ProvideFinaccle() {
  return (
    <div className="provide-finaccle-section">
      <div className="container">
        <h2> What we provide at Finaccle</h2>
        <div className="provide-finaccle-details-alignment">
          <div className="provide-finaccle-child-details">
            <div className="budget-flex-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Comprehensive Budgeting Solutions</h4>
                <p>
                  Finaccle offers comprehensive outsourced budgeting services
                  customized to your business needs.
                </p>
                <p>
                  From budget planning and forecasting to variance analysis, we
                  cover all aspects of budget management.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>Experienced Financial Experts</h4>
                <p>
                  Access to a team of experienced financial professionals with
                  expertise in budgeting and financial analysis.
                </p>
                <p>
                  Benefit from their insights and guidance to develop accurate
                  and realistic budgets aligned with your business goals.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon3} alt="Icon1" />
                <h4>Customized Budgeting Processes</h4>
                <p>
                  Customized budgeting processes designed to suit your industry,
                  size, and unique business requirements.
                </p>
                <p>
                  Customization of budgeting templates, workflows, and reporting
                  structures to streamline the budgeting process.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Strategic Planning and Goal Setting</h4>
                <p>
                  Strategic planning sessions to set realistic financial goals
                  and objectives for your business.
                </p>
                <p>
                  Collaboration with our experts to align budgeting strategies
                  with your long-term growth plans and financial targets.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>Regular Monitoring and Reporting</h4>
                <p>
                  Continuous monitoring of budget performance against targets
                  and benchmarks.
                </p>
                <p>
                  Regular reporting and analysis to track variances, identify
                  trends, and make informed decisions to optimize financial
                  performance.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
            }}
          >
            <h3>Advantages</h3>
            <div className="provide-finaccle-grid two-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon4} alt="Icon1" />
                <h4>Cost Efficiency and Resource Optimization</h4>
                <p>
                  Cost-effective alternative to hiring in-house budgeting
                  professionals.
                </p>
                <p>
                  Optimize resources by outsourcing budgeting services, reducing
                  overhead costs and improving efficiency.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Expert Guidance and Insights</h4>
                <p>
                  Gain access to expert guidance and insights from seasoned
                  financial professionals.
                </p>
                <p>
                  Benefit from their expertise to develop robust budgeting
                  strategies and make informed financial decisions.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>Improved Accuracy and Reliability:</h4>
                <p>
                  Ensure accuracy and reliability in budgeting processes through
                  rigorous validation and review.
                </p>
                <p>
                  Minimize errors and discrepancies in budget forecasts to
                  enhance financial planning and decision-making.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon4} alt="Icon1" />
                <h4>Scalability and Flexibility</h4>
                <p>
                  Scalable solutions customized to accommodate your business
                  growth and evolving budgeting needs.
                </p>
                <p>
                  Flexible engagement models to adapt to changing requirements
                  and budgeting priorities.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
              marginBottom: "60px",
            }}
          >
            <h3>Why Choose Finaccle </h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon7} alt="Icon1" />
                <h4>Cost effective service</h4>
                <p>
                  At Finaccle, we understand the importance of
                  cost-effectiveness in today's competitive business landscape.
                  That's why we are committed to providing budgeting services
                  that offer exceptional value without compromising on quality.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Customized Solutions for Your Business</h4>
                <p>
                  We offer customized budgeting solutions customized to your
                  specific business objectives and financial goals.
                </p>
                <p>
                  Our personalized approach ensures that your budgeting
                  processes are aligned with your unique requirements and
                  preferences.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>Dedicated Support and Collaboration</h4>
                <p>
                  Partner with Finaccle for dedicated support and collaboration
                  throughout the budgeting process.
                </p>
                <p>
                  Our team works closely with you to understand your needs,
                  address concerns, and deliver results that drive financial
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
