import "./Footer.scss";
import React, { useContext } from "react";
import FinaccleLogo from "../../assets/logos/Finaccle-Logo.png";
import FacebookIcon from "../../assets/icons/facebook-icon.png";
import InstagramIcon from "../../assets/icons/instagram-icon.png";
import ShareIcon from "../../assets/icons/share-icon.png";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../store/GlobalState";
import { useMediaQuery } from "@mui/material";

function Footer() {
  const navigate = useNavigate();
  const { state } = useContext(GlobalContext);

  const bookkeepingData = [
    {
      serviceTitle: "Accounts Payable",
      routeName: `/payable/${state.country}`,
    },
    {
      serviceTitle: "Accounts Receivable",
      routeName: `/receivable/${state.country}`,
    },
    {
      serviceTitle: "Billing And Invoicing",
      routeName: `/billing-and-invoicing/${state.country}`,
    },
    {
      serviceTitle: "Reconciliation",
      routeName: `/reconciliation/${state.country}`,
    },
    {
      serviceTitle: "Customer Order Processing",
      routeName: `/customer-order-processing/${state.country}`,
    },
    {
      serviceTitle: "Accounts Outsourcing",
      routeName: `/account-outsourcing/${state.country}`,
    },
    {
      serviceTitle: "Off Shore Accounting",
      routeName: `/off-shore-accounting/${state.country}`,
    },
    {
      serviceTitle: "Net Suite Accounting",
      routeName: `/net-suite-accounting/${state.country}`,
    },
    {
      serviceTitle: "Zoho Books Accounting",
      routeName: `/zoho-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Quick Books Accounting",
      routeName: `/quick-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Xero Accounting",
      routeName: `/xero/${state.country}`,
    },
    {
      serviceTitle: "Catch Up Accounting",
      routeName: `/catch-up-accounting/${state.country}`,
    },
    {
      serviceTitle: "Monthly Bookkeeping",
      routeName: `/monthly-book-keeping/${state.country}`,
    },
    {
      serviceTitle: "Retro Accounting",
      routeName: `/retro-accounting/${state.country}`,
    },
  ];

  const advisoryData = [
    {
      serviceTitle: "Payroll",
      routeName: `/payroll/${state.country}`,
    },
    {
      serviceTitle: "Financial Reporting",
      routeName: `/financial-reporting/${state.country}`,
    },
    {
      serviceTitle: "Budgeting And Forecasting",
      routeName: `/budgeting-and-forecasting/${state.country}`,
    },
    {
      serviceTitle: "CFM",
      routeName: `/cfm/${state.country}`,
    },
  ];

  return (
    <div className="container">
      <div className="main-footer-wrapper">
        <div className="footer-content-wrapper">
          <div className="footer-logo-links-wrapper">
            <div className="logo-wrapper">
              <img src={FinaccleLogo} alt="Finaccle" className="img-logo" />
            </div>

            <div className="main-footer-links-wrapper">
              <div className="left-footer-links-wrapper">
                <div className="left-footer-alignment">
                  <div className="footer-links-title">Bookkeeping</div>
                  <div className="content-alignment">
                    {bookkeepingData?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => navigate(data.routeName)}
                          className="service-item"
                        >
                          {data.serviceTitle}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="left-footer-alignment">
                  <div className="footer-links-title">Advisory</div>
                  <div className="content-alignment">
                    {advisoryData?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => navigate(data.routeName)}
                          className="service-item"
                        >
                          {data.serviceTitle}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className="footer-links-title"
                    onClick={() => navigate(`/virtual-cfo/${state.country}`)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Virtual CFO
                  </div>
                </div>

                <div className="left-footer-alignment">
                  <div className="footer-links-title">Resources</div>
                  <div className="content-alignment">
                    <div
                      onClick={() => navigate("/pricing")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Pricing
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/blogs")}
                    >
                      Blog
                    </div>
                  </div>
                </div>

                <div className="left-footer-alignment">
                  <div className="footer-links-title">Company</div>
                  <div className="content-alignment">
                    <div
                      onClick={() => navigate("/about-us")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      About Us
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-footer-links-wrapper">
                <div className="footer-content-contactus">
                  <div className="footer-links-title remove-margin">
                    Contact Us
                  </div>
                  <div className="address-wrapper">
                    <span>info.finaacleglobal@gmail.com</span>
                  </div>

                  <div className="social-wrapper">
                    <a
                      href="mailto:info.finaacleglobal@gmail.com"
                      className="call-now-button"
                    >
                      Contact Us
                    </a>

                    <div className="follow-us-wrapper">
                      <div className="contact-us-title">Follow us</div>
                      <div className="social-icons-wrapper">
                        <img src={FacebookIcon} alt="Facebook" />
                        <img src={ShareIcon} alt="Share" />
                        <img src={InstagramIcon} alt="Instagram" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
