import "./BorderBottomCard.scss";
import React from "react";

function BorderBottomCard({ cardData }) {
  return (
    <div className="our-services-card-wrapper">
      {cardData.map((data, index) => {
        return (
          <div className="services-card-content" key={index}>
            <div className="card-title">{data.serviceTitle}</div>
            <div className="card-body-content">{data.serviceDesc}</div>
          </div>
        );
      })}
    </div>
  );
}

export default BorderBottomCard;
