import React from "react";
import "./CatchServices.scss";

export default function CatchServices() {
  return (
    <div className="container">
      <div className="retro-services-section">
        <h3>
          What's included in Finaccle's <br />
          retro accounting service?
        </h3>

        <div className="retro-services-details-alignment">
          <div className="retro-services-grid">
            <div className="retro-services-grid-item">
              <h4>Tailored support offered by a skilled professional</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Receive customized assistance from experienced professionals who
                understand your specific needs, ensuring a seamless experience
                as we bring your outdated financial records up to speed through
                retro accounting service
              </p>
            </div>
            <div className="retro-services-grid-item">
              <h4>Tax time made easier, no hassle</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Experience a simplified tax season with our retro accounting
                service, where we handle the tedious tasks of updating your
                financial records, ensuring a stress-free tax preparation
                process for you.
              </p>
            </div>
            <div className="retro-services-grid-item">
              <h4>Comprehensive financial reporting features</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Detailed financial reporting tools provided as part of our retro
                accounting service, offering comprehensive insights into your
                historical financial data.
              </p>
            </div>
            <div className="retro-services-grid-item">
              <h4>Accurate Reconciliation of Past Transaction</h4>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                Benefit from precise reconciliation of your past transactions,
                ensuring your financial records are complete and accurate,
                thereby enhancing the reliability of your financial statements
                and overall financial health.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
