import React from "react";
import "./WhatCatch.scss";
export default function WhatCatch() {
  return (
    <div className="container">
      <div className="watch-catch-section">
        <div className="watch-catch-heading">
          <h2>What is Monthly Bookkeeping?</h2>
          <p>
            Monthly bookkeeping entails the regular upkeep of financial records,
            including income, expenses, and transactions, on a monthly basis. It
            ensures accurate financial reporting, aids in decision-making, and
            facilitates compliance with regulations, enhancing overall business
            efficiency and transparency.
          </p>
          <p>
            Empower your business with our expert monthly accounting services.
            Our dedicated team ensures meticulous recording, seamless
            reconciliation, and insightful analysis, giving you the clarity and
            confidence to drive your business forward. Trust us to handle your
            monthly accounting needs with precision and professionalism, so you
            can focus on what you do best - growing your business.
          </p>
        </div>
      </div>
    </div>
  );
}
