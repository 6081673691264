import React from "react";
import "./CatchServices.scss";

export default function CatchServices() {
  return (
    <div className="container">
      <div className="catch-services-section">
        <h3>
          Catch up bookkeeping <br />
          service includes
        </h3>

        <div className="catch-services-details-alignment">
          <div className="catch-services-grid">
            <div className="catch-services-grid-item">
              <p>
                {" "}
                Get your tax documents ready for filing ahead of tax season.
              </p>
            </div>
            <div className="catch-services-grid-item">
              <p> Match up your incoming and outgoing payments.</p>
            </div>
            <div className="catch-services-grid-item">
              <p> Rapid and swift annual bookkeeping cleanup.</p>
            </div>
            <div className="catch-services-grid-item">
              <p> Furnish balance sheets and other financial information.</p>
            </div>
            <div className="catch-services-grid-item">
              <p>
                {" "}
                Reconcile bank and book records to make sure all transactions
                are recorded.
              </p>
            </div>
            <div className="catch-services-grid-item">
              <p> Categorization of all transactions</p>
            </div>
          </div>

          {/* <div className="catch-services-alignment">
                    <textarea></textarea>
                </div> */}
        </div>
      </div>
    </div>
  );
}
