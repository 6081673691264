import React from "react";
import "./VirtualCfo.scss";
import VirtualBanner from "./VirtualBanner/VirtualBanner";
import VirtualCfoTab from "./VirtualCfoTab/VirtualCfoTab";
import HiringVirtualCFO from "./HiringVirtualCFO/HiringVirtualCFO";
import WhatVirtualCfo from "./WhatVirtualCfo/WhatVirtualCfo";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import AllServices from "../../components/AllServices/AllServices";

export default function VirtualCfo() {
  return (
    <div>
      <VirtualBanner />
      <VirtualCfoTab />
      <HiringVirtualCFO />
      <WhatVirtualCfo />
      <OurProcess />
      <TestimonialCards />
      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
      <div className="vistual-explore-button">
        <AllServices />
      </div>
      <div className="container">
        <LatestBlog />
      </div>
    </div>
  );
}
