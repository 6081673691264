import React, { useContext } from "react";
import "./AccountOutsourcingBanner.scss";
import CollebarteImg from "../../../assets/images/financial-banner.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { GlobalContext } from "../../../store/GlobalState";

export default function AccountOutsourcingBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="account-outsourcing-banner-section ">
      <div className="container">
        <div className="banner-grid">
          <div className="bannner-left-wrapper">
            <div className="bannner-left">
              <img src={CollebarteImg} alt="CollebarteImg" />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Financial Reporting {state.country}</h1>
            <h2>
              At Finaccle, we offer detailed financial reporting services, and
              it can help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Generating accurate financial statements</li>
                <li>Providing insights into financial performance</li>
                <li>Ensuring regulatory compliance</li>
                <li>Facilitating informed business decisions</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h1>What is Financial Reporting?</h1>
          <p>
            Financial reporting is the process of tracking, analysing, and
            reporting your company's revenue. This allows you and any investors
            to make more informed judgments about how to run the business. These
            reports look at resource use and cash flow to determine the
            financial health of the company. Financial reporting is the process
            of tracking, analysing, and reporting your company's revenue. This
            allows you and any investors to make more informed judgments about
            how to run the business. These reports look at resource use and cash
            flow to determine the financial health of the company.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
