import React from "react";
import "./ProvideFinaccle.scss";
import Icon1 from "../../../assets/icons/user-expert.png";
import Icon2 from "../../../assets/icons/accounting-solution.png";
import Icon3 from "../../../assets/icons/performance.png";
import Icon4 from "../../../assets/icons/data-analysis.png";
import Icon5 from "../../../assets/icons/cost-effective.png";
import Icon6 from "../../../assets/icons/risk-mitigation.png";
import Icon7 from "../../../assets/icons/data-analysis.png";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function ProvideFinaccle() {
  return (
    <div className="provide-finaccle-section">
      <div className="container">
        <h2> What we provide at Finaccle</h2>
        <div className="provide-finaccle-details-alignment">
          <div className="provide-finaccle-child-details">
            <div className="provide-finaccle-grid four-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Expert Analysis and Interpretation:</h4>
                <p>
                  Access to skilled financial analysts for in-depth analysis and
                  interpretation of financial reports.
                </p>
                <p>
                  Expert guidance on understanding financial statements to drive
                  informed decision-making.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>Customized Reporting Solutions</h4>
                <p>
                  Tailored financial reporting solutions to meet the specific
                  needs and objectives of your business.
                </p>
                <p>
                  Customization of reports to provide insights relevant to your
                  industry, stakeholders, and strategic goals.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon3} alt="Icon1" />
                <h4>Performance Monitoring and Benchmarking</h4>
                <p>
                  Continuous monitoring of financial performance through
                  comprehensive reporting and analysis.
                </p>
                <p>
                  Benchmarking against industry standards and peers to identify
                  areas for improvement and strategic opportunities.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon4} alt="Icon1" />
                <h4>Data Integrity and Accuracy</h4>
                <p>
                  Ensuring data integrity and accuracy in financial reporting
                  through rigorous validation and verification processes.
                </p>
                <p>
                  Implementation of controls and procedures to minimize errors
                  and discrepancies in financial statements.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
            }}
          >
            <h3>Advantages</h3>
            <div className="provide-finaccle-grid four-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Access to Specialized Expertise</h4>
                <p>
                  Access to a team of financial experts with specialized
                  knowledge in financial reporting and analysis.
                </p>
                <p>
                  Benefit from their expertise and insights to enhance the
                  accuracy and reliability of your financial statements.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>Risk Mitigation</h4>
                <p>
                  Employ skilled professionals to oversee financial reporting,
                  ensuring adherence to accounting standards and regulations,
                  and conducting regular reviews to identify and correct errors.
                </p>
                <p>
                  Implement robust validation processes, including thorough
                  audits and cross-checks, to verify data accuracy, detect
                  discrepancies, and ensure the reliability of financial
                  statements before final reporting.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Cost Efficiency and Resource Optimization</h4>
                <p>
                  Cost-effective alternative to hiring in-house financial
                  reporting professionals.
                </p>
                <p>
                  Optimize resources by outsourcing financial reporting advisory
                  services, freeing up internal resources for core business
                  activities.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon7} alt="Icon1" />
                <h4>Strategic Insights and Decision Support</h4>
                <p>
                  Gain valuable insights into your financial performance to
                  support strategic decision-making.
                </p>
                <p>
                  Leverage expert analysis and interpretation of financial
                  reports to identify opportunities for growth and optimization.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
              marginBottom: "60px",
            }}
          >
            <h3>Why Choose Finaccle </h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Cost effective service</h4>
                <p>
                  At Finaccle, we understand the importance of
                  cost-effectiveness in today's competitive business landscape.
                  That's why we are committed to providing budgeting services
                  that offer exceptional value without compromising on quality.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>Tailored Solutions for Your Business </h4>
                <p>
                  Finaccle offers customized financial reporting solutions
                  tailored to your unique business requirements and objectives.
                </p>
                <p>
                  Receive personalized service and dedicated support to address
                  your specific financial reporting challenges effectively.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon7} alt="Icon1" />
                <h4>Commitment to Accuracy and Integrity</h4>
                <p>
                  At Finaccle, we prioritize accuracy, integrity, and
                  transparency in financial reporting.
                </p>
                <p>
                  Our stringent quality control measures ensure that your
                  financial statements are reliable and compliant with
                  regulatory standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
