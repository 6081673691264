import React from "react";
import "./CFM.scss";
import AccountOutsourcingBanner from "./AccountOutsourcingBanner/AccountOutsourcingBanner";
import ProvideFinaccle from "./ProvideFinaccle/ProvideFinaccle";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";

function CFM() {
  return (
    <div>
      <AccountOutsourcingBanner />
      <ProvideFinaccle />
      <OurProcess />
      <TestimonialCards />

      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
    </div>
  );
}

export default CFM;
