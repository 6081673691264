import React from "react";
import "./CustomizedPricing.scss";
import BorderBottomCard from "../../../components/BorderBottomCard/BorderBottomCard";
import ContactUsModal from "../../../components/ContactUsModal/ContactUsModal";

export default function CustomizedPricing() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pricingData = [
    {
      serviceTitle: "Full-time Resource",
      serviceDesc:
        "Access a dedicated resource for consistent support, billed every month.",
    },
    {
      serviceTitle: "Half Resource",
      serviceDesc:
        "Choose flexible support with a resource available four hours daily, billed monthly.",
    },
    {
      serviceTitle: "Project-Based Pricing",
      serviceDesc:
        "One-time project? We provide customized quotes tailored to your requirements.",
    },
  ];

  return (
    <div
      className="customizedPricing-section"
      style={{
        marginBottom: "60px",
      }}
    >
      <h3>Make Your Custom Plan</h3>
      <div
        className="our-services-wrapper"
        style={{
          marginTop: "20px",
        }}
      >
        <BorderBottomCard cardData={pricingData} />
      </div>

      <div className="contact-us-btn-wrapper">
        <button onClick={handleClickOpen}>Contact Us</button>
      </div>
      {open && <ContactUsModal open={open} handleClose={handleClose} />}
    </div>
  );
}
