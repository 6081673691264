import React from "react";
import "./CatchOption.scss";

import Icon1 from "../../../assets/icons/icon-1.svg";
import Icon2 from "../../../assets/icons/icon-2.svg";
import Icon3 from "../../../assets/icons/icon-3.svg";
import Icon4 from "../../../assets/icons/icon-4.svg";
import Icon5 from "../../../assets/icons/icon-5.svg";
import Icon6 from "../../../assets/icons/icon-6.svg";

export default function CatchOption() {
  return (
    <div className="container">
      <div className="catch-option-section">
        <div className="catch-option-grid">
          <div className="catch-option-box">
            <div className="box-grid ">
              <div className="box-details-alignment">
                <h3>Tidy, Tax-ready financials</h3>
                <p>
                  Get your financial records organized and ready for tax season
                  hassle-free, ensuring smooth filing and compliance.
                </p>
              </div>
              <div className="icon-box">
                <img src={Icon1} alt="Icon1" />
              </div>
            </div>
          </div>
          <div className="catch-option-box">
            <div className="box-grid ">
              <div className="box-details-alignment">
                <h3>Expert Catch up Team</h3>
                <p>
                  Get your financial records organized and ready for tax season
                  hassle-free, ensuring smooth filing and compliance.
                </p>
              </div>
              <div className="icon-box">
                <img src={Icon2} alt="Icon2" />
              </div>
            </div>
          </div>
          <div className="catch-option-box">
            <div className="box-grid ">
              <div className="box-details-alignment">
                <h3>Ideal for financial comfort</h3>
                <p>
                  Get your financial records organized and ready for tax season
                  hassle-free, ensuring smooth filing and compliance.
                </p>
              </div>
              <div className="icon-box">
                <img src={Icon3} alt="Icon3" />
              </div>
            </div>
          </div>
          <div className="catch-option-box">
            <div className="box-grid ">
              <div className="box-details-alignment">
                <h3>Catching upon bookkeeping</h3>
                <p>
                  Get your financial records organized and ready for tax season
                  hassle-free, ensuring smooth filing and compliance.
                </p>
              </div>
              <div className="icon-box">
                <img src={Icon4} alt="Icon4" />
              </div>
            </div>
          </div>
          <div className="catch-option-box">
            <div className="box-grid ">
              <div className="box-details-alignment">
                <h3>Updated books of accounts</h3>
                <p>
                  Get your financial records organized and ready for tax season
                  hassle-free, ensuring smooth filing and compliance.
                </p>
              </div>
              <div className="icon-box">
                <img src={Icon5} alt="Icon5" />
              </div>
            </div>
          </div>
          <div className="catch-option-box">
            <div className="box-grid ">
              <div className="box-details-alignment">
                <h3>Prompt Execution</h3>
                <p>
                  Get your financial records organized and ready for tax season
                  hassle-free, ensuring smooth filing and compliance.
                </p>
              </div>
              <div className="icon-box">
                <img src={Icon6} alt="Icon6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
