import React, { useState } from "react";
import "./PricingStructure.scss";
import ContactUsModal from "../../../components/ContactUsModal/ContactUsModal";

export default function PricingStructure() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="pricingStructure-section">
      <div
        className="pricingStructure-heading"
        style={{
          marginBottom: "40px",
        }}
      >
        <h2>Pricing Structure</h2>
        <p>Choose your plan</p>
      </div>

      <div className="pricingStructure-all-details">
        <label>Pricing</label>
        <div
          className="pricing-box"
          style={{
            marginTop: "20px",
          }}
        >
          <div className="pricicng-top">
            <h3>Hourly</h3>
            <div className="rightSide">
              <p>
                $8 <span>/Hour</span>
              </p>
            </div>
          </div>

          <div className="pricing-bottom">
            <p>
              For occasional assistance, engage our services on an hourly basis
              with transparent billing.
            </p>
            <button onClick={handleClickOpen}>Get a quote now</button>
          </div>
        </div>
      </div>

      {open && <ContactUsModal open={open} handleClose={handleClose} />}
    </div>
  );
}
