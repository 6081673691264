import React from "react";
import "./LatestBlog.scss";
import Blog1 from "../../assets/images/blog-1.png";
import Blog2 from "../../assets/images/blog-2.png";
import Blog3 from "../../assets/images/blog-3.png";
import accmistakes from "../../assets/images/accounting-mistake.svg";
import ecommerce from "../../assets/images/e-commerce.svg";
import differenceaccounting from "../../assets/images/diff-accounting.svg";
import { useNavigate } from "react-router-dom";
export default function LatestBlog() {
  const navigate = useNavigate();

  return (
    <div className="main-latest-blog-wrapper">
      <div className="latest-blog-post-wrapper">
        <div className="blog-title-wrapper">
          <div className="our-services-title">Latest Blog Posts</div>
          <div className="blog-description">
            Discover how our tailored solutions drive success. Read our latest
            blog for insights and inspiration. Join us on the journey to
            excellence!
          </div>
        </div>

        <div className="blog-card-wrapper">
          <div className="blog-image-wrapper">
            <div
              style={{
                backgroundColor: "#cee2fb",
                border: "none",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <img src={accmistakes} alt="Blog" />
            </div>
            <div
              style={{
                backgroundColor: "#cee2fb",
                border: "none",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <img src={ecommerce} alt="Blog" />
            </div>
            <div
              style={{
                backgroundColor: "#cee2fb",
                border: "none",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <img src={differenceaccounting} alt="Blog" />
            </div>
          </div>
        </div>
      </div>
      <div className="blog-button-wrapper">
        <button className="blog-button" onClick={() => navigate("/blogs")}>
          Explore Blogs
        </button>
      </div>
    </div>
  );
}
