import React, { useContext } from "react";
import "./AccountOutsourcingBanner.scss";
import CollebarteImg from "../../../assets/images/off-shore-banner.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { GlobalContext } from "../../../store/GlobalState";

export default function AccountOutsourcingBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="account-outsourcing-banner-section ">
      <div className="container">
        <div className="banner-grid">
          <div className="bannner-left-wrapper">
            <div className="bannner-left">
              <img src={CollebarteImg} alt="CollebarteImg" />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Off Shore Accounting {state.country}</h1>
            <h2>
              At Finaccle we provide off shore accounting solutions, and it can
              help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Leveraging global accounting expertise</li>
                <li>Cost-effective accounting solutions</li>
                <li>Ensuring compliance with international standards</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h1>What is Offshore Accounting?</h1>
          <p>
            Offshore accounting services involve outsourcing accounting
            functions to specialized providers in offshore locations, offering
            cost savings and high-quality standards. These services provide
            scalability, flexibility, and access to specialized expertise,
            making them an attractive global accounting solution option.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
