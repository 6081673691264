import React, { useState } from "react";
import "./CatchTable.scss";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import ContactUsModal from "../../../components/ContactUsModal/ContactUsModal";

export default function CatchTable() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="catch-table-section">
        <div className="container">
          <div className="catch-table">
            <table>
              <thead>
                <tr>
                  <th>What we do</th>
                  <th>What you get</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>
                      Assess past financial records to identify <br /> gaps and
                      inaccuracies.
                    </p>
                  </td>
                  <td>
                    <p>
                      Complete and accurate financial history, <br /> ensuring
                      all transactions are accounted for.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Reconcile bank statements with book entries <br /> to
                      ensure accuracy.
                    </p>
                  </td>
                  <td>
                    <p>
                      Peace of mind knowing that bank <br />
                      transactions match your financial records.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Categorize transactions to ensure they're <br /> reflected
                      in the correct accounts.
                    </p>
                  </td>
                  <td>
                    <p>
                      Clearer financial insights with well-organized <br />{" "}
                      expense and income categories.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Provide detailed reports on financial health <br />
                      and past discrepancies.
                    </p>
                  </td>
                  <td>
                    <p>
                      Informed decision-making based on comprehensive reports{" "}
                      <br />
                      detailing financial performance and areas of concern.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Provide detailed reports on financial health <br />
                      and past discrepancies.
                    </p>
                  </td>
                  <td>
                    <p>
                      Streamlined financial processes for more efficient and{" "}
                      <br /> accurate bookkeeping going forward.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="catch-button">
            <button onClick={handleClickOpen}>Get your quote </button>
            <div className="bg"></div>
          </div>

          <div
            className="dedication-section"
            style={{
              marginBottom: "60px",
            }}
          >
            <h3>Your books will receive the dedicated focus they need!</h3>
            <p>
              The skilled bookkeepers in our Finaccle team offer speedy and
              precise catch-up services, freeing up numerous hours for you to
              focus on other aspects of your business. We're ready to tackle any
              challenge, even if your books are behind by 1, 2, or 3 years.
              Don't worry – we've got your back and we're here to assist you.
            </p>
          </div>
        </div>
      </div>
      <BookCallBannerButton />
      {open && <ContactUsModal open={open} handleClose={handleClose} />}
    </>
  );
}
