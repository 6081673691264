import React from "react";
import "./RetroAccounting.scss";
import CatchupAccountingBanner from "./CatchupAccountingBanner/CatchupAccountingBanner";
import CatchTable from "./CatchTable/CatchTable";
import CatchServices from "./CatchServices/CatchServices";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import HowWork from "./HowWork/HowWork";
import AllServices from "../../components/AllServices/AllServices";
import BookCallBannerButton from "../../components/BookCallBannerButton/BookCallBannerButton";

function RetroAccounting() {
  return (
    <div>
      <CatchupAccountingBanner />
      <CatchTable />
      <CatchServices />

      <div className="container">
        <div className="catch-table-section">
          <div
            className="dedication-section"
            style={{
              padding: 0,
            }}
          >
            <h3>
              Retro accounting with endless professional assistance available!
            </h3>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "90px",
        }}
      >
        <BookCallBannerButton />
      </div>

      <HowWork />
      <OurProcess />
      <TestimonialCards />

      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
      <div className="vistual-explore-button">
        <AllServices />
      </div>
      <div className="container">
        <LatestBlog />
      </div>
    </div>
  );
}

export default RetroAccounting;
