import React, { useContext } from "react";
import "./CatchupAccountingBanner.scss";
import BannerImg from "../../../assets/images/catchup-banner-img.png";
import TeamImg from "../../../assets/images/team-img.png";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function CatchupAccountingBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="catchup-accounting-banner-section">
      <div className="container">
        <div className="catchup-accounting-banner-grid">
          <div className="banner-img-wrapper">
            <div className="banner-img">
              <img src={BannerImg} alt="BannerImg" />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Catch Up Accounting {state.country}</h1>
            <h2>
              At Finaccle we provide catch up accounting solutions, and it can
              help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Bringing your books up to date</li>
                <li>Rectifying past accounting discrepancies</li>
                <li>Ensuring compliance and accuracy</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="hiringVirtualCfoHeading"
          style={{
            paddingBottom: "90px",
          }}
        >
          <h1>Catchup Accounting</h1>
          <p>Effortless Catch Up Accounting: Tailored for Your Needs</p>
        </div>

        <div
          className="catchup-accounting-bottom-alignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="catchup-accounting-bottom-grid">
            <div className="catchup-team-details">
              <h2>Is your business falling behind in keeping good records?</h2>
              <p>
                This is your chance to brush up on your accounting and get back
                into the industry.
              </p>
            </div>
            <div className="team-img-wrapper">
              <div className="team-img">
                <img src={TeamImg} alt="TeamImg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
