import "./NetSuitAccounting.scss";
import React, { useContext } from "react";
import NetSuitBanner from "../../assets/images/net-suit-banner.svg";
import AnimatedSection from "../../components/Animation/AnimatedSection";
import NetSuit1 from "../../assets/images/Cloud hosting-bro.svg";
import NetSuit2 from "../../assets/images/net-suit-2.svg";
import NetSuit3 from "../../assets/images/net-suit-3.svg";
import NetSuit4 from "../../assets/images/net-suit-4.svg";
import NetSuit5 from "../../assets/images/net-suit-5.svg";
import NetSuit6 from "../../assets/images/net-suit-6.svg";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import { GlobalContext } from "../../store/GlobalState";
import BookCallBannerButton from "../../components/BookCallBannerButton/BookCallBannerButton";
import AllServices from "../../components/AllServices/AllServices";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

function NetSuitAccounting() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();

  const ourServicesData = [
    {
      serviceTitle: "Accounts Outsourcing",
      serviceDesc: "Reduce Costs with Expert Outsourced Accounting Services.",
      serviceLink: `/account-outsourcing/${state.country}`,
    },
    {
      serviceTitle: "Accounts Payable",
      serviceDesc:
        "Optimize Cash Flow with Professional Accounts Payable Outsourcing.",
      serviceLink: `/payable/${state.country}`,
    },
    {
      serviceTitle: "Accounts Receivable",
      serviceDesc:
        "Accelerate Revenue with Outsourced Accounts Receivable Management.",
      serviceLink: `/receivable/${state.country}`,
    },
    {
      serviceTitle: "Quickbooks Accounting",
      serviceDesc:
        "Streamline Operations  Outsourced QuickBooks Accounting Support.",
      serviceLink: `/quick-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Zoho Accounting",
      serviceDesc:
        "Focus on Core Business Functions with Zoho Accounting Outsourcing Solutions",
      serviceLink: `/zoho-books-accounting/${state.country}`,
    },
  ];

  return (
    <div className="main-net-suit-wrapper">
      <div className="net-suit-content-wrapper">
        <div className="container">
          <div className="net-suit-banner-wrapper">
            <div className="net-suit-img-wrapper">
              <div className="net-suit-img">
                <img src={NetSuitBanner} alt="" />
              </div>
            </div>
            <div className="virtual-banner-text">
              <h1>Net Suite Accounting {state.country}</h1>
              <h2>
                At Finaccle we provide Net Suite accounting solutions, and it
                can help you with:
              </h2>
              <div className="service-list-wrapper">
                <ul>
                  <li>Optimizing your Net Suite accounting processes</li>
                  <li>Ensuring seamless integration and functionality</li>
                  <li>Enhancing financial reporting and analysis</li>
                  <li>
                    Implementing best financial practices for your business
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="hiringVirtualCfoHeading"
            style={{
              marginBottom: "60px",
            }}
          >
            <h2>Net Suite</h2>
            <p>
              Net Suite is a cloud-based enterprise resource planning (ERP)
              system created by Oracle Corporation. Its set of business
              management tools includes sophisticated accounting functionality.
              Net Suite is a powerful accounting software for efficiently
              handling financial activities. This includes features like a
              general ledger system for tracking all financial transactions,
              managing accounts payable and receivable, generating financial
              reports and analysis, budgeting and forecasting, managing fixed
              assets, and ensuring compliance with accounting standards and
              regulations. Net Suite's accounting module is intended to expedite
              financial operations, increase insight into financial data, and
              enable informed decision-making for organizations of all sizes.
            </p>
          </div>
        </div>

        <BookCallBannerButton />

        <div className="container">
          <div className="payableServicesSection">
            <div className="payableServicesHeading">
              <h2>Benefits of Net Suite Accounting</h2>
            </div>

            <div
              className="payableServicesDetailsAlignment"
              style={{
                marginBottom: "60px",
              }}
            >
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Cloud-Based Access:</h3>
                    <p>
                      With Net Suite, consumers can access financial data from
                      any location with an internet connection thanks to its
                      cloud-based platform. This flexibility makes it possible
                      to collaborate remotely and track financial performance in
                      real time.
                    </p>
                  </div>
                </AnimatedSection>
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit1} alt="ServicesImg1" />
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit2} alt="ServicesImg2" />
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Bank Reconciliation:</h3>
                    <p>
                      By automatically importing bank transactions and comparing
                      them with matching data in the system, Net Suite
                      simplifies bank reconciliation. This function streamlines
                      the reconciliation procedure and guarantees the accuracy
                      of financial data.
                    </p>
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Expense Tracking:</h3>
                    <p>
                      Net Suite facilitates effective expense tracking for
                      organizations by classifying transactions, allowing
                      receipts to be attached, and balancing out expenditures
                      against bank feeds. This function makes budget management
                      easier and offers insights into expenditure trends.
                    </p>
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit3} alt="ServicesImg3" />
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit4} alt="ServicesImg4" />
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Multi-Currency Support:</h3>
                    <p>
                      Net Suite allows users to record transactions and generate
                      reports in many currencies, which is beneficial for firms
                      that operate globally. Accurate financial reporting is
                      made easier and accounting for international activities is
                      made simpler by this feature.
                    </p>
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Security</h3>
                    <p>
                      Prioritizing data security, Net Suite protects sensitive
                      financial data with multi-factor authentication, frequent
                      backups, and cutting-edge encryption. The strong security
                      features of Net Suite allow users to feel secure knowing
                      that their data is protected from unwanted access.
                    </p>
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit5} alt="ServicesImg5" />
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit6} alt="ServicesImg6" />
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Financial Reporting</h3>
                    <p>
                      A range of configurable financial reports, such as cash
                      flow statements, balance sheets, budget vs. actual
                      reports, and profit and loss statements, are available
                      with Net Suite. These reports assist in making
                      well-informed decisions by offering insights into the
                      performance of businesses.
                    </p>
                  </div>
                </AnimatedSection>
              </div>
            </div>
          </div>
        </div>

        <BookCallBannerButton />

        <div className="container">
          <div className="seeking-book-keeping-alignment">
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                paddingTop: 0,
              }}
            >
              <h3>Seeking bookkeeping services for Net Suite?</h3>
              <p>
                The days of accounting being just a table with a stack of papers
                are long gone! Nowadays, Net Suite bookkeeping services are used
                by the majority of small and medium-sized enterprises. It is
                usually preferable to leave the accounting duties to a
                professional who can handle them successfully and efficiently,
                even when the software is straightforward and easy to use.
                Finaccle offers Net Suite bookkeeping services to our clients in
                the US. Many of our clients located in India, Singapore, Hong
                Kong, New Zealand, Australia, the UK, Canada, Ireland, and
                Europe, show complete trust on us regarding the use of Net Suite
                Accounting software.
              </p>
            </div>
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                marginBottom: "60px",
              }}
            >
              <h3>Our Mastery of Net Suite Applications</h3>
              <p>
                We at Finaccle live, breathe, and work with Net Suite Software.
                Our group has a great deal of experience and knowledge when it
                comes to using Net Suite to its fullest. To the advantage of our
                clients, we have been at the forefront of implementing and
                perfecting this ground-breaking solution.
              </p>
              <p>
                We have been able to hone our skills via our experience with Net
                Suite Software, so we can guarantee that we can provide you with
                unmatched support when using this platform for your financial
                needs. Because of the breadth of our industry experience, we can
                customize our offerings to precisely match your needs.
              </p>
            </div>
          </div>
        </div>

        <BookCallBannerButton />

        <div className="container">
          <div className="seeking-book-keeping-alignment">
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                paddingTop: 0,
              }}
            >
              <h3
                style={{
                  maxWidth: "815px",
                }}
              >
                Accounting and Bookkeeping Services offered by us using Net
                Suite Software
              </h3>
            </div>
          </div>

          <div
            className="our-services-wrapper"
            style={{
              padding: "90px 0 0 0",
            }}
          >
            <AnimatedSection animationType="fade-up" duration="1000">
              <div className="our-services-card-wrapper">
                {ourServicesData.map((data, index) => {
                  return (
                    <div
                      className="services-card-content"
                      style={{
                        cursor: "pointer",
                      }}
                      key={index}
                      onClick={() => {
                        navigate(data.serviceLink);
                      }}
                    >
                      <div className="card-title">{data.serviceTitle}</div>
                      <div className="card-body-content">
                        {data.serviceDesc}
                      </div>
                    </div>
                  );
                })}
              </div>
            </AnimatedSection>
          </div>
        </div>

        <div className="container">
          <div className="seeking-book-keeping-alignment">
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                paddingTop: 0,
              }}
            >
              <h3>What Help Is Finaccle Able to Provide?</h3>
              <p
                style={{
                  maxWidth: "895px",
                  margin: "0 auto",
                  marginTop: "10px",
                }}
              >
                Modern businesses are empowered by Net Suite Software, which is
                more than just a software platform. In addition to having access
                to Net Suite's features, working with Finaccle gives you access
                to a team of professionals who can help you realize the full
                potential of the software for your financial success. Our
                unwavering commitment is to provide the best bookkeeping and
                accounting services.
              </p>
            </div>
          </div>
        </div>

        <OurProcess />
        <TestimonialCards />
        <div className="software-expertise-main">
          <SoftwareExpertise />
        </div>
        <div className="container">
          <div className="book-button">
            <AllServices />
          </div>
        </div>
        <div className="container">
          <LatestBlog />
        </div>
      </div>
    </div>
  );
}

export default NetSuitAccounting;
