import React, { useContext } from "react";
import "./CatchupAccountingBanner.scss";
import BannerImg from "../../../assets/images/Ebook-bro.svg";
import TeamImg from "../../../assets/images/bookkeeing-team.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { GlobalContext } from "../../../store/GlobalState";

export default function CatchupAccountingBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="catchup-accounting-banner-section">
      <div className="container">
        <div className="catchup-accounting-banner-grid">
          <div className="banner-img">
            <img src={BannerImg} alt="BannerImg" />
          </div>
          <div className="virtual-banner-text">
            <h1>Monthly Bookkeeping {state.country}</h1>
            <h2>
              At Finaccle we provide monthly bookkeeping solutions, and it can
              help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Providing consistent and reliable bookkeeping services</li>
                <li>Ensuring up-to-date financial records</li>
                <li>Helping you make informed business decisions</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="hiringVirtualCfoHeading"
          style={{
            paddingBottom: "90px",
          }}
        >
          <h2>Monthly Bookkeeping</h2>
          <p>Monthly Tracking Made Easy: Optimize Your Business Finances</p>
          <p>
            Get ready to team up with top-notch business specialists. We offer
            online bookkeeping coupled with human assistance, providing the
            expert financial support your business needs. Say goodbye to
            financial worries - with us, your business gets the professional
            financial solution it truly deserves.
          </p>
        </div>
        <div
          className="catchup-accounting-bottom-alignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="catchup-accounting-bottom-grid">
            <div className="catchup-team-details">
              <h2>Is your business falling behind in keeping good records?</h2>
              <p>
                This is your chance to brush up on your accounting and get back
                into the industry.
              </p>
            </div>
            <div className="team-img-wrapper">
              <div className="team-img">
                <img
                  src={TeamImg}
                  alt="TeamImg"
                  style={{
                    width: "100%",
                    height: "250px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
