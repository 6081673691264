import React from "react";
import "./AutomationServices.scss";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function AutomationServices() {
  return (
    <div className="automation-services-section">
      <div className="container">
        <div className="automation-services-heading">
          <h2>What we provide at Finaccle</h2>
        </div>

        <div className="automation-services-details-alignment">
          <div className="automation-services-grid">
            <div className="automation-services-grid-item">
              <div className="automation-services-box">
                <h4>
                  Efficient Customer Order Processing Solutions with Finaccle
                </h4>
                <div className="link">
                  <a>
                    Finaccle offers efficient customer order processing
                    solutions to streamline sales and fulfillment operations.
                  </a>
                  <a>
                    From order capture to delivery, Finaccle ensures seamless
                    processing to enhance customer satisfaction.
                  </a>
                </div>
              </div>

              <div className="automation-services-box">
                <h4>Comprehensive Order Management with Finaccle</h4>
                <div className="link">
                  <a>
                    Finaccle's customer order processing covers a wide range of
                    tasks, including order entry, tracking, and fulfillment.
                  </a>
                  <a>
                    By managing the entire order lifecycle, Finaccle ensures
                    accuracy and timeliness in every transaction.
                  </a>
                </div>
              </div>

              <div className="automation-services-box">
                <h4>Automated Order Processing Powered by Finaccle</h4>
                <div className="link">
                  <a>
                    Finaccle automates order processing tasks to minimize manual
                    intervention and reduce processing time.
                  </a>
                  <a>
                    With Finaccle's automation capabilities, businesses can
                    handle high volumes of orders efficiently and accurately.
                  </a>
                </div>
              </div>
            </div>

            <div className="automation-services-grid-item">
              <div className="automation-services-box">
                <h4>Integration with Sales Channels Supported by Finaccle:</h4>

                <div className="link">
                  <a>
                    Finaccle seamlessly integrates with various sales channels,
                    including e-commerce platforms and POS systems.
                  </a>
                  <a>
                    This integration ensures real-time order synchronization and
                    centralized order management for enhanced visibility and
                    control.
                  </a>
                </div>
              </div>

              <div className="automation-services-box">
                <h4>
                  Customized Order Processing Workflows Tailored by Finaccle
                </h4>
                <div className="link">
                  <a>
                    Finaccle offers customized order processing workflows
                    tailored to meet the specific needs and requirements of each
                    business.
                  </a>
                  <a>
                    Whether handling complex order routing or implementing
                    special order handling instructions, Finaccle adapts to
                    unique business processes.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
