import React from "react";
import "./HowWork.scss";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
export default function HowWork() {
  return (
    <div className="how-work-section">
      <div className="container">
        <div className="how-work-alignment">
          <div className="how-work-heading">
            <h2>How Retro Accounting Works</h2>
          </div>
          <div className="how-work-details-alignment">
            <AnimatedSection animationType="fade-up" duration="900">
              <div className="how-word-grid-alignment">
                <div className="step-details-alignment">
                  <div className="step-box">
                    <span>1</span>
                  </div>
                  <p>Step 1</p>
                </div>

                <div className="step-right-details-alignment">
                  <div className="box-alignment">
                    <h6>Assessment of Past Records</h6>
                    <p>
                      The first step in retro accounting is to gather old
                      financial records, such as receipts, invoices, and bank
                      statements, from previous years.
                    </p>
                  </div>
                </div>
              </div>
            </AnimatedSection>

            <AnimatedSection animationType="fade-up" duration="1100">
              <div className="how-word-grid-alignment">
                <div className="step-details-alignment">
                  <div className="step-box">
                    <span>2</span>
                  </div>
                  <p>Step 2</p>
                </div>

                <div className="step-right-details-alignment">
                  <div
                    className="box-alignment"
                    style={{
                      minHeight: "auto",
                    }}
                  >
                    <h6>Data Entry and Organization</h6>
                    <p>
                      Entering the data into accounting system and this involves
                      organizing the information by categories such as
                    </p>
                  </div>
                </div>
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>
    </div>
  );
}
