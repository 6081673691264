import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import AboutUs from "./pages/AboutUs/AboutUs";
import Pricing from "./pages/Pricing/Pricing";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import AccountOutsourcing from "./pages/AccountOutsourcing/AccountOutsourcing";
import VirtualCfo from "./pages/VirtualCfo/VirtualCfo";
import AccountPayeble from "./pages/AccountPayeble/AccountPayeble";
import CatchupAccounting from "./pages/CatchupAccounting/CatchupAccounting";
import NetSuitAccounting from "./pages/NetSuitAccounting/NetSuitAccounting";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AccountReceivable from "./pages/AccountReceivable/AccountReceivable";
import BillingAndInvoicing from "./pages/BillingAndInvoicing/BillingAndInvoicing";
import Reconciliation from "./pages/Reconciliation/Reconciliation";
import CustomerOrderProcessing from "./pages/CustomerOrderProcessing/CustomerOrderProcessing";
import Payroll from "./pages/Payroll/Payroll";
import OffShoreAccounting from "./pages/OffShoreAccounting/OffShoreAccounting";
import FinancialReporting from "./pages/FinancialReporting/FinancialReporting";
import BudgetingAndForecasting from "./pages/BudgetingAndForecasting/BudgetingAndForecasting";
import CFM from "./pages/CFM/CFM";
import RetroAccounting from "./pages/RetroAccounting/RetroAccounting";
import ZohoBooksAccounting from "./pages/ZohoBooksAccounting/ZohoBooksAccounting";
import QuickBooksAccounting from "./pages/QuickBooksAccounting/QuickBooksAccounting";
import MonthlyBookKeeping from "./pages/MonthlyBookKeeping/MonthlyBookKeeping";
import XeroAccounting from "./pages/XeroAccounting/XeroAccounting";
import { GlobalContext } from "./store/GlobalState";
import { Circles } from "react-loader-spinner";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/BlogDetails/BlogDetails";

function App() {
  const { state } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.country) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [state.country]);

  return (
    <Router>
      <Header />
      <ScrollToTop />
      {loading && (
        <div className="main-loader-wrapper">
          <Circles
            height="60"
            width="60"
            color="#00EDAC"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loading}
          />
        </div>
      )}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/virtual-cfo/:country" element={<VirtualCfo />} />
        <Route path="/payable/:country" element={<AccountPayeble />} />
        <Route path="/receivable/:country" element={<AccountReceivable />} />
        <Route
          path="/billing-and-invoicing/:country"
          element={<BillingAndInvoicing />}
        />
        <Route path="/reconciliation/:country" element={<Reconciliation />} />
        <Route
          path="/customer-order-processing/:country"
          element={<CustomerOrderProcessing />}
        />
        <Route path="/payroll/:country" element={<Payroll />} />
        <Route
          path="/account-outsourcing/:country"
          element={<AccountOutsourcing />}
        />
        <Route
          path="/off-shore-accounting/:country"
          element={<OffShoreAccounting />}
        />
        <Route
          path="/financial-reporting/:country"
          element={<FinancialReporting />}
        />
        <Route
          path="/budgeting-and-forecasting/:country"
          element={<BudgetingAndForecasting />}
        />
        <Route path="/cfm/:country" element={<CFM />} />
        <Route
          path="/catch-up-accounting/:country"
          element={<CatchupAccounting />}
        />
        <Route
          path="/monthly-book-keeping/:country"
          element={<MonthlyBookKeeping />}
        />
        <Route
          path="/retro-accounting/:country"
          element={<RetroAccounting />}
        />
        <Route
          path="/net-suite-accounting/:country"
          element={<NetSuitAccounting />}
        />
        <Route
          path="/zoho-books-accounting/:country"
          element={<ZohoBooksAccounting />}
        />
        <Route
          path="/quick-books-accounting/:country"
          element={<QuickBooksAccounting />}
        />
        <Route path="/xero/:country" element={<XeroAccounting />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:blogTitle" element={<BlogDetails />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
