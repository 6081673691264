import "./XeroAccounting.scss";
import React, { useContext } from "react";
import NetSuitBanner from "../../assets/images/net-suit-banner.svg";
import AnimatedSection from "../../components/Animation/AnimatedSection";
import NetSuit2 from "../../assets/images/net-suit-2.svg";
import NetSuit3 from "../../assets/images/net-suit-3.svg";
import NetSuit4 from "../../assets/images/net-suit-4.svg";
import NetSuit5 from "../../assets/images/net-suit-5.svg";
import NetSuit6 from "../../assets/images/net-suit-6.svg";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import Cloud from "../../assets/images/Cloud hosting-bro.svg";
import { GlobalContext } from "../../store/GlobalState";
import BookCallBannerButton from "../../components/BookCallBannerButton/BookCallBannerButton";
import AllServices from "../../components/AllServices/AllServices";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

function XeroAccounting() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const { state } = useContext(GlobalContext);
  const navigate = useNavigate();

  const ourServicesData = [
    {
      serviceTitle: "Accounts Outsourcing",
      serviceDesc: "Reduce Costs with Expert Outsourced Accounting Services.",
      serviceLink: `/account-outsourcing/${state.country}`,
    },
    {
      serviceTitle: "Accounts Payable",
      serviceDesc:
        "Optimize Cash Flow with Professional Accounts Payable Outsourcing.",
      serviceLink: `/payable/${state.country}`,
    },
    {
      serviceTitle: "Accounts Receivable",
      serviceDesc:
        "Accelerate Revenue with Outsourced Accounts Receivable Management.",
      serviceLink: `/receivable/${state.country}`,
    },
    {
      serviceTitle: "Quickbooks Accounting",
      serviceDesc:
        "Streamline Operations  Outsourced QuickBooks Accounting Support.",
      serviceLink: `/quick-books-accounting/${state.country}`,
    },
    {
      serviceTitle: "Zoho Accounting",
      serviceDesc:
        "Focus on Core Business Functions with Zoho Accounting Outsourcing Solutions",
      serviceLink: `/zoho-books-accounting/${state.country}`,
    },
  ];

  return (
    <div className="main-net-suit-wrapper">
      <div className="net-suit-content-wrapper">
        <div className="container">
          <div className="net-suit-banner-wrapper">
            <div className="net-suit-img-wrapper">
              <div className="net-suit-img">
                <img src={NetSuitBanner} alt="" />
              </div>
            </div>
            <div className="virtual-banner-text">
              <h1>Xero Accounting {state.country}</h1>
              <h2>
                At Finaccle we provide Xero accounting solutions, and it can
                help you with:
              </h2>
              <div className="service-list-wrapper">
                <ul>
                  <li>Enhancing your Xero experience</li>
                  <li>Ensuring accurate and timely financial records</li>
                  <li>Streamlining your accounting processes</li>
                  <li>
                    Implementing best financial practices for your business
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="hiringVirtualCfoHeading"
            style={{
              marginBottom: "60px",
            }}
          >
            <h2>Xero</h2>
            <p>
              A company's performance can be further enhanced by achieving
              financial efficiency, which is what every company aims for. One of
              the crucial divisions that contributes to achieving financial
              efficiency is bookkeeping and accounting. The accounting program
              Xero is quite helpful and has simplified bookkeeping and
              accounting tasks.
            </p>
            <p>
              You may record financial transactions more easily and with greater
              accuracy with Xero bookkeeping. Some people may find XERO a little
              challenging to use; in order to use it correctly, you need to be a
              better Xero-certified bookkeeper. XERO is a very practical
              accounting program that many organizations are using for their
              bookkeeping and accounting needs.
            </p>
          </div>
        </div>
        <BookCallBannerButton />

        <div className="container">
          <div className="payableServicesSection">
            <div className="payableServicesHeading">
              <h2>Benefits of using Xero for accounting</h2>
            </div>
            <div
              className="payableServicesDetailsAlignment"
              style={{
                marginBottom: "60px",
              }}
            >
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Cloud-Based Access:</h3>
                    <p>
                      With Xero, consumers can access financial data from any
                      location with an internet connection thanks to its
                      cloud-based platform. This flexibility makes it possible
                      to collaborate remotely and track financial performance in
                      real time.
                    </p>
                  </div>
                </AnimatedSection>
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={Cloud} alt="ServicesImg1" />
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit2} alt="ServicesImg2" />
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Bank Reconciliation:</h3>
                    <p>
                      By automatically importing bank transactions and comparing
                      them with matching data in the system, Xero simplifies
                      bank reconciliation. This function streamlines the
                      reconciliation procedure and guarantees the accuracy of
                      financial data.
                    </p>
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Expense Tracking:</h3>
                    <p>
                      Xero facilitates effective expense tracking for
                      organizations by classifying transactions, allowing
                      receipts to be attached, and balancing out expenditures
                      against bank feeds. This function makes budget management
                      easier and offers insights into expenditure trends.
                    </p>
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit3} alt="ServicesImg3" />
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit4} alt="ServicesImg4" />
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Multi-Currency Support:</h3>
                    <p>
                      Xero allows users to record transactions and generate
                      reports in many currencies, which is beneficial for firms
                      that operate globally. Accurate financial reporting is
                      made easier and accounting for international activities is
                      made simpler by this feature.
                    </p>
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Security</h3>
                    <p>
                      Prioritizing data security, Xero protects sensitive
                      financial data with multi-factor authentication, frequent
                      backups, and cutting-edge encryption. The strong security
                      features of Xero allow users to feel secure knowing that
                      their data is protected from unwanted access.
                    </p>
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit5} alt="ServicesImg5" />
                  </div>
                </AnimatedSection>
              </div>
              <div className="payableServicesGrid">
                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-right"}
                  duration="600"
                >
                  <div className="payableServices-details-img">
                    <img src={NetSuit6} alt="ServicesImg6" />
                  </div>
                </AnimatedSection>

                <AnimatedSection
                  animationType={isMobile ? "fade" : "fade-left"}
                  duration="600"
                >
                  <div className="payableServices-details-alignment">
                    <h3>Financial Reporting</h3>
                    <p>
                      A range of configurable financial reports, such as cash
                      flow statements, balance sheets, budget vs. actuals
                      reports, and profit and loss statements, are available
                      with Xero. These reports assist in making well-informed
                      decisions by offering insights into the performance of
                      businesses.
                    </p>
                  </div>
                </AnimatedSection>
              </div>
            </div>
          </div>
        </div>
        <BookCallBannerButton />

        <div className="container">
          <div className="seeking-book-keeping-alignment">
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                paddingTop: 0,
              }}
            >
              <h3>Seeking bookkeeping services for Xero?</h3>
              <p>
                The days of accounting being just a table with a stack of papers
                are long gone! Nowadays, Xero bookkeeping services are used by
                the majority of small and medium-sized enterprises. It is
                usually preferable to leave the accounting duties to a
                professional who can handle them successfully and efficiently,
                even when the software is straightforward and easy to use.
                Finaccle offers Xero bookkeeping services to our clients in the
                US. Many of our clients located in India, Singapore, Hong Kong,
                New Zealand, Australia, the UK, Canada, Ireland, and Europe,
                show complete trust on us regarding the use of Xero Accounting
                software.
              </p>
            </div>
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                marginBottom: "60px",
              }}
            >
              <h3>Our Mastery of Xero Applications</h3>
              <p>
                We at Finaccle live, breathe, and work with Xero Software. Our
                group has a great deal of experience and knowledge when it comes
                to using Xero to its fullest. To the advantage of our clients,
                we have been at the forefront of implementing and perfecting
                this ground-breaking solution.
              </p>
              <p>
                We have been able to hone our skills via our experience with
                Xero Software, so we can guarantee that we can provide you with
                unmatched support when using this platform for your financial
                needs. Because of the breadth of our industry experience, we can
                customize our offerings to precisely match your needs.
              </p>
            </div>
          </div>
        </div>

        <BookCallBannerButton />

        <div className="container">
          <div className="seeking-book-keeping-alignment">
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                paddingTop: 0,
              }}
            >
              <h3
                style={{
                  maxWidth: "815px",
                }}
              >
                Accounting and Bookkeeping Services offered by us using Xero
                Software
              </h3>
            </div>
          </div>

          <div
            className="our-services-wrapper"
            style={{
              padding: "90px 0 0 0",
            }}
          >
            <AnimatedSection animationType="fade-up" duration="1000">
              <div className="our-services-card-wrapper">
                {ourServicesData.map((data, index) => {
                  return (
                    <div
                      className="services-card-content"
                      style={{
                        cursor: "pointer",
                      }}
                      key={index}
                      onClick={() => {
                        navigate(data.serviceLink);
                      }}
                    >
                      <div className="card-title">{data.serviceTitle}</div>
                      <div className="card-body-content">
                        {data.serviceDesc}
                      </div>
                    </div>
                  );
                })}
              </div>
            </AnimatedSection>
          </div>
        </div>

        <div className="container">
          <div className="seeking-book-keeping-alignment">
            <div
              className="seeking-book-keeping-wrapper"
              style={{
                paddingTop: 0,
              }}
            >
              <h3>What Help Is Finaccle Able to Provide?</h3>
              <p
                style={{
                  maxWidth: "895px",
                  margin: "0 auto",
                  marginTop: "10px",
                }}
              >
                Modern businesses are empowered by Xero Software, which is more
                than just a software platform. In addition to having access to
                Xero's features, working with Finaccle gives you access to a
                team of professionals who can help you realize the full
                potential of the software for your financial success. Our
                unwavering commitment is to provide the best bookkeeping and
                accounting services.
              </p>
            </div>
          </div>
        </div>

        <OurProcess />
        <TestimonialCards />
        <div className="software-expertise-main">
          <SoftwareExpertise />
        </div>
        <div className="container">
          <div className="book-button">
            <AllServices />
          </div>
        </div>
        <div className="container">
          <LatestBlog />
        </div>
      </div>
    </div>
  );
}

export default XeroAccounting;
