import React from "react";
import "./AutomationServices.scss";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function AutomationServices() {
  return (
    <div className="automation-services-section">
      <div className="container">
        <div className="automation-services-heading">
          <h2>Accounts Payable Automation Services We Offer</h2>
          <p>We provide the following Accounts Payable Automation Services</p>
        </div>

        <div className="automation-services-details-alignment">
          <div className="automation-services-grid">
            <div className="automation-services-grid-item">
              <div className="automation-services-box">
                <h4>Data Capture</h4>
                <p>Management of paper-based documents that includes</p>
                <div className="link">
                  <a>Sorting</a>
                  <a>Preparation of Batches</a>
                  <a>Indexing Documents</a>
                  <a>Auditing & Reconciliation</a>
                  <a>Storage of documents & retrieval</a>
                  <a>Processing and receiving the incoming mail</a>
                </div>
              </div>

              <div className="automation-services-box">
                <h4>Suspect Duplicate Analysis</h4>
                <p>Control and auditing mechanism</p>
                <div className="link">
                  <a>Automated as well as manual data entry services</a>
                  <a>Three-way database matching</a>
                  <a>E-Invoice processing</a>
                </div>
              </div>

              <div className="automation-services-box">
                <h4>Other Accounts Payable Services</h4>
                <p>This includes</p>
                <div className="link">
                  <a>Utility bill management</a>
                  <a>Expenses & travel cost management</a>
                  <a>Tax reporting management</a>
                  <a>Supplier management</a>
                  <a>Special project reports</a>
                </div>
              </div>
            </div>

            <div className="automation-services-grid-item">
              <div className="automation-services-box">
                <h4>Data Processing</h4>
                <p>Management of the exception transactions that include</p>
                <div className="link">
                  <a>Exception and Approval routing</a>
                  <a>Minimised month-end process of closing</a>
                  <a>Audit trail documentation</a>
                </div>
              </div>

              <div className="automation-services-box">
                <h4>Disbursement Services</h4>
                <p>This includes</p>
                <div className="link">
                  <a>Return check handling</a>
                  <a>Secure and efficient stock checking</a>
                  <a>Invoice & Mail handling</a>
                  <a>Electronic payment handling</a>
                  <a>Online check proofing</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
