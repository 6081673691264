import React, { useContext } from "react";
import "./AccountPayebleBanner.scss";
import AccountPayebleBannerImg from "../../../assets/images/account-receivable.svg";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function AccountPayebleBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="account-payeble-banner-section">
      <div className="container">
        <div className="account-payeble-banner-grid">
          <div className="account-payeble-banner-img-wrapper">
            <div className="account-payeble-banner-img">
              <img
                src={AccountPayebleBannerImg}
                alt="AccountPayebleBannerImg"
              />
            </div>
          </div>

          <div className="virtual-banner-text">
            <h1>Accounts Receivable {state.country}</h1>
            <h2>
              At Finaccle we provide accounts receivable solutions, and it can
              help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Efficiently managing your receivables</li>
                <li>Improving your cash inflow</li>
                <li>Reducing outstanding receivables</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>Accounts receivable Accounting</h2>
          <p>
            Receivables are legally enforceable payment claims maintained by a
            firm for goods or services provided to customers. These claims are
            often in the form of invoices generated by a business and delivered
            to the client for payment within a certain time frame.
          </p>
          <p>
            Accounts receivable are listed as assets on a balance sheet and
            indicate money owing by entities to the company for items or
            services sold on credit.
          </p>

          <h2
            style={{
              marginTop: "30px",
            }}
          >
            Requirement of receivables Management Systems for a company{" "}
          </h2>
          <p>
            A good Receivables Management system not only helps a firm develop,
            but it also improves customer relationships. It also plays an
            important role in a company's day-to-day operations, which leads to
            growth and success.
          </p>
          <p>
            Getting paid on time is essential, and there is no use in expanding
            bed debts and the cost of your business. We have extensive
            experience in providing a superior accounts receivable management
            service for businesses. This will ultimately help businesses enhance
            their cash flow.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
