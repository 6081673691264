import React from "react";
import "./VirtualCfoTab.scss";
import RightIcon from "../../../assets/icons/right-sign.svg";
export default function VirtualCfoTab() {
  return (
    <div className="container">
      <div className="virtual-cfo-tab-section">
        <div className="virtual-cfo-tab-flex">
          <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Accounting & Bookeeping</p>
            </div>
          </div>
          <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Implementing best financial practice for your business</p>
            </div>
          </div>

          <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Business acquisition & Mergers</p>
            </div>
          </div>

          <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Funding evaluation</p>
            </div>
          </div>

          <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Financial analysis</p>
            </div>
          </div>
          <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Business valuation</p>
            </div>
          </div>
          {/* <div className="cfo-box-alignment">
            <div className="cfo-grid-alignment">
              <div className="rightSign">
                <img src={RightIcon} alt="RightIcon" />
              </div>
              <p>Tax Planning</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
