import React from "react";
import "./PayableServices.scss";
import ServicesImg1 from "../../../assets/images/services-1.png";
import ServicesImg2 from "../../../assets/images/services-2.png";
import ServicesImg3 from "../../../assets/images/Experts-bro.svg";
import ServicesImg4 from "../../../assets/images/services-4.png";
import ServicesImg5 from "../../../assets/images/services-5.png";
import ServicesImg6 from "../../../assets/images/services-6.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function PayableServices() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="payableServicesSection">
      <div className="container">
        <div className="payableServicesHeading">
          <h2>Benefits of Outsourcing Accounting Payable Services</h2>
        </div>
      </div>

      <div className="container">
        <div
          className="payableServicesDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Cost Savings</h3>
                <p>
                  By outsourcing Accounting Payable services, firms can
                  dramatically cut their operational costs. Outsourcing partners
                  may often accomplish Accounting Payable duties more
                  efficiently and at a lesser cost than in-house teams due to
                  economies of scale and access to specialized expertise.
                </p>
              </div>
            </AnimatedSection>
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg1} alt="ServicesImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg2} alt="ServicesImg2" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Focus on Core Activities</h3>
                <p>
                  Outsourcing Accounting Payable functions allows firms to
                  reallocate resources and concentrate on core activities that
                  directly contribute to development and competitiveness.
                  Businesses that delegate routine operations such as invoice
                  processing and payment administration might free up time and
                  energy to focus on strategic objectives.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Access to Expertise</h3>
                <p>
                  Outsourcing partners have specific knowledge and expertise in
                  accounting and finance. They stay up to date on industry best
                  practices, legislation, and technological changes to ensure
                  Accounting Payable procedures are accurate, compliant, and
                  efficient.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg3} alt="ServicesImg3" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg4} alt="ServicesImg4" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Enhanced Accuracy and Compliance</h3>
                <p>
                  Professional outsourcing firms use skilled personnel and
                  advanced technologies to assure regulatory compliance. This
                  helps to eliminate errors, the possibility of fraud, and the
                  heavy penalties associated with noncompliance.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Improved Efficiency</h3>
                <p>
                  Outsourcing Account Payable functions improves efficiency by
                  streamlining procedures, reducing manual workloads, and
                  introducing automation wherever possible. This results in
                  faster response times for invoice processing, approvals, and
                  payments, increasing overall efficiency and productivity in
                  the finance department.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg5} alt="ServicesImg5" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg6} alt="ServicesImg6" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Risk Mitigation</h3>
                <p>
                  Outsourcing Account Payable services can assist reduce the
                  risks related with staffing shortages, turnover, and internal
                  operational disruptions. Businesses that rely on a dedicated
                  outsourcing partner can maintain continuity and reliability in
                  their Account Payable processes, especially during periods of
                  organizational change or crisis.
                </p>
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
