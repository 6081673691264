import React, { useRef, useState } from "react";
import "./ContactUsModal.scss";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";

function ContactUsModal({ open, handleClose }) {
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    plan: "Full-time Resource",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_e3zyjeq", "template_0pg11w9", form.current, {
        publicKey: "CeXFqwAv_htf4Jph_",
      })
      .then(
        () => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            plan: "Full-time Resource",
            message: "",
          });
          handleClose();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="contact-us-modal"
      className="custom-modal-wrapper"
      sx={{
        "& .MuiDialog-paper": {
          width: "650px",
        },
      }}
    >
      <form ref={form} onSubmit={handleSubmit}>
        <DialogTitle
          id="contact-us-modal"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Contact Us</span>
          <span
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                gap: "30px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "5px",
                  width: "100%",
                }}
              >
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  value={formData.firstName}
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    fontFamily: "Anek Tamil",
                    height: "34px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "5px",
                  width: "100%",
                }}
              >
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={formData.lastName}
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    fontFamily: "Anek Tamil",
                    height: "34px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                gap: "20px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "5px",
                  width: "100%",
                }}
              >
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    fontFamily: "Anek Tamil",
                    height: "34px",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "5px",
                  width: "100%",
                }}
              >
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  value={formData.phone}
                  style={{
                    width: "100%",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    fontFamily: "Anek Tamil",
                    height: "34px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1,1fr)",
                gap: "5px",
                width: "100%",
              }}
            >
              <label>Select your plan</label>
              <select
                name="plan"
                onChange={handleChange}
                value={formData.plan}
                style={{
                  width: "100%",
                  border: "1px solid #c4c4c4",
                  borderRadius: "4px",
                  fontFamily: "Anek Tamil",
                  height: "40px",
                  fontSize: "14px",
                  padding: "10px",
                }}
              >
                <option value="Full-time Resource" selected>
                  Full-time Resource
                </option>
                <option value="Half Resource">Half Resource</option>
                <option value="Project-Based Pricing">
                  Project-Based Pricing
                </option>
              </select>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1,1fr)",
                gap: "5px",
                width: "100%",
              }}
            >
              <label>Message</label>
              <textarea
                name="message"
                onChange={handleChange}
                value={formData.message}
                rows="10"
                style={{
                  width: "100%",
                  border: "1px solid #c4c4c4",
                  borderRadius: "4px",
                  fontFamily: "Anek Tamil",
                  resize: "none",
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ContactUsModal;
