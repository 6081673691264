import React, { useContext } from "react";
import "./AccountPayebleBanner.scss";
import AccountPayebleBannerImg from "../../../assets/images/account-payeble-img.png";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function AccountPayebleBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="account-payeble-banner-section">
      <div className="container">
        <div className="account-payeble-banner-grid">
          <div className="account-payeble-banner-img-wrapper">
            <div className="account-payeble-banner-img">
              <img
                src={AccountPayebleBannerImg}
                alt="AccountPayebleBannerImg"
              />
            </div>
          </div>

          <div className="virtual-banner-text">
            <h1>Accounts Payable {state.country}</h1>
            <h2>
              At Finaccle we provide accounts payable solutions, and it can help
              you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Streamlining your payment processes</li>
                <li>Ensuring timely payments to vendors</li>
                <li>Managing and optimizing cash flow</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>Accounts payable Accounting</h2>
          <p>
            Accounts Payable is a form of credit that businesses can use from
            suppliers to delay payment for products or services. It can disrupt
            cash flow, damage supplier relationships, and reduce overall profit.
            Credit periods are allowed by creditors and suppliers, and some
            businesses may receive discounts for timely payments. However,
            non-payment can lead to breach of payment terms with suppliers,
            potentially resulting in legal issues. Accounts Payable outsourcing
            services can help businesses manage their payable needs effectively.
          </p>
          <p>
            These services assist in the efficient management of accounts
            payable by creating daily, weekly, or monthly reports based on the
            client's needs. Our fast online accounts payable services boost cash
            flow and preserve vendor relationships, enabling a seamless cash
            flow for organizations across multiple cities.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
