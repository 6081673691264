import React from "react";
import "./Payroll.scss";
import AccountPayebleBanner from "./AccountPayebleBanner/AccountPayebleBanner";
import PayableServices from "./PayableServices/PayableServices";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import BorderBottomCard from "../../components/BorderBottomCard/BorderBottomCard";
import AllServices from "../../components/AllServices/AllServices";

function Payroll() {
  const ourServicesData = [
    {
      serviceTitle: "",
      serviceDesc:
        "Payroll data entry, payroll data processing including tax calculation",
    },
    {
      serviceTitle: "",
      serviceDesc:
        "Calculating the exact amount due to employees after deduction of taxes",
    },
    {
      serviceTitle: "",
      serviceDesc:
        "Map the timesheets and prepare employee payslips, salary, and PF",
    },
    {
      serviceTitle: "",
      serviceDesc: "On-time payslip generation and emailing",
    },
    {
      serviceTitle: "",
      serviceDesc: "Recordkeeping & administration",
    },
    {
      serviceTitle: "",
      serviceDesc: "Monthly, Annually Employer or Statutory compliance",
    },
  ];

  return (
    <div>
      <AccountPayebleBanner />
      <PayableServices />

      <div className="container">
        <div
          className="our-services-wrapper"
          style={{
            padding: "90px 0 0 0",
          }}
        >
          <div className="payableServicesHeading">
            <h2>Benefits of Outsourcing Payroll Services</h2>
          </div>
          <BorderBottomCard cardData={ourServicesData} />
        </div>
      </div>

      <OurProcess />
      <TestimonialCards />
      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
      <div className="vistual-explore-button">
        <AllServices />
      </div>
      <div className="container">
        <LatestBlog />
      </div>
    </div>
  );
}

export default Payroll;
