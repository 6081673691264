import React from "react";
import "./OurProcess.scss";
import Step1 from "../../assets/icons/step-1.svg";
import Step2 from "../../assets/icons/step-2.svg";
import Step3 from "../../assets/icons/step-3.svg";
import Step4 from "../../assets/icons/step-4.svg";
import Step5 from "../../assets/icons/step-5.svg";
import RightArrow from "../../assets/icons/right-arrow.svg";

export default function OurProcess() {
  return (
    <div className="container">
      <div className="ourProcess-section">
        <div className="ourProcess-heading">
          <h2>Our Process</h2>
          <p>
            Achieve effective consultation and outsource your business
            hassle-free with online accounting services.
          </p>
        </div>

        <div className="ourProcess-details-alignment">
          <div className="ourProcess-details-grid">
            <div className="ourProcess-details-grid-item">
              <div className="icon-alignment ">
                <img src={Step1} alt="Step1" />

                <div className="procees-arrow">
                  <img src={RightArrow} alt="RightArrow" />
                </div>
              </div>
              <p>
                Establish <br />
                Contact
              </p>
            </div>
            <div className="ourProcess-details-grid-item">
              <div className="icon-alignment ">
                <img src={Step2} alt="Step2" />
                <div className="procees-arrow">
                  <img src={RightArrow} alt="RightArrow" />
                </div>
              </div>
              <p>
                Understanding <br />
                Requirements
              </p>
            </div>
            <div className="ourProcess-details-grid-item">
              <div className="icon-alignment ">
                <img src={Step3} alt="Step3" />
                <div className="procees-arrow">
                  <img src={RightArrow} alt="RightArrow" />
                </div>
              </div>
              <p>
                Work
                <br />
                Proposal
              </p>
            </div>
            <div className="ourProcess-details-grid-item">
              <div className="icon-alignment ">
                <img src={Step4} alt="Step4" />
                <div className="procees-arrow">
                  <img src={RightArrow} alt="RightArrow" />
                </div>
              </div>
              <p>
                Pricing and
                <br />
                contracting{" "}
              </p>
            </div>
            <div className="ourProcess-details-grid-item">
              <div className="icon-alignment ">
                <img src={Step5} alt="Step5" />
              </div>
              <p>
                Start <br />
                Delivering{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
