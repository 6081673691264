import React from "react";
import "./BlogDetails.scss";
import { useNavigate, useParams } from "react-router-dom";
import { blogListData } from "../../constants/blogs";

function BlogDetails() {
  const { blogTitle } = useParams();
  const navigate = useNavigate();

  const blog = blogListData.find((item) => item.blogURL === blogTitle);

  return (
    <div className="main-blog-details-wrapper">
      <div className="container">
        <div className="blog-details-alignment">
          <div className="blog-details-left-alignment">
            <div className="blog-details-content-wrapper">
              <div className="blog-details-header">
                <h1>{blog.blogTitle.toUpperCase()}</h1>
              </div>
              <div className="blog-details-body">
                <div className="blog-details-body-content">
                  <p className="benefit-overview">{blog.blogDescription}</p>

                  {blog.isList && (
                    <div>
                      <ul className="benefits-list">
                        {blog.benefitsList?.map((item, index) => {
                          return (
                            <li key={index}>
                              <p className="benefit-title">{item.title}</p>
                              <p className="benefit-description">
                                {item.description}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                  {!blog.isList && (
                    <div className="blog-guide-content-wrapper">
                      {blog.blogGuideContent?.map((item, index) => {
                        return (
                          <div key={index}>
                            <p className="benefit-title">{item.guideTitle}</p>
                            <p className="benefit-overview">
                              {item.guideDescription}
                            </p>
                            <ul className="guide-list">
                              {item.guideList?.map((listitem, index) => {
                                return (
                                  <li key={index}>
                                    <p className="list-title">
                                      {listitem.listTitle}
                                    </p>
                                    <p className="list-description">
                                      {listitem.listDescription}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div>
                    <p className="benefit-title">{blog.conclusion.title}</p>
                    <p className="benefit-overview">
                      {blog.conclusion.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-details-right-alignment">
            {blogListData.map((item, index) => (
              <div key={index} className="blog-details-right-alignment-item">
                <img src={item.blogImage} alt={item.blogTitle} />
                <h3
                  onClick={() =>
                    navigate(
                      `/blogs/${item.blogTitle
                        .toLowerCase()
                        .replace(/ /g, "-")}`
                    )
                  }
                >
                  {item.blogTitle}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
