import React from "react";
import "./WhatCatch.scss";
export default function WhatCatch() {
  return (
    <div className="container">
      <div className="watch-catch-section">
        <div className="watch-catch-heading">
          <h2>What is Catch up Accounting?</h2>
          <p>
            Catch up accounting involves reviewing past records of a company's
            finances and fixing any mistakes or problems over a certain period,
            getting everything back on track for business. Catch-up bookkeeping
            serves to show the overall financial health of company. You could
            identify the neglected parts that require quick attention and
            correction during this process.
          </p>
        </div>
      </div>
    </div>
  );
}
