import React from "react";
import "./Pricing.scss";
import PricingBanner from "./PricingBanner/PricingBanner";
import PricingStructure from "./PricingStructure";
import CustomizedPricing from "./CustomizedPricing";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import CallIcon from "../../assets/icons/book-call.svg";
import AllServices from "../../components/AllServices/AllServices";

function Pricing() {
  const handleClickOpen = () => {
    window.open("https://calendly.com/info-finaccleglobal/30min", "_blank");
  };

  return (
    <div>
      <PricingBanner />
      <div className="container">
        <PricingStructure />
      </div>
      <div className="container">
        <CustomizedPricing />
      </div>
      <div
        style={{
          marginBottom: "60px",
        }}
      >
        <div className="book-call-banner-main-wrapper">
          <div className="icon-text-wrapper">
            <img src={CallIcon} alt="" />
            <div className="book-text">
              <span>Still confused about what you want?</span>
            </div>
          </div>
          <div className="book-call-btm-wrapper">
            <button onClick={handleClickOpen}>Book a call now</button>
          </div>
        </div>
      </div>

      <AllServices />

      <div className="container">
        <OurProcess />
        <TestimonialCards />
      </div>
    </div>
  );
}

export default Pricing;
