import React, { useContext } from "react";
import "./AccountOutsourcingBanner.scss";
import CollebarteImg from "../../../assets/images/collaboration-img.png";
import { GlobalContext } from "../../../store/GlobalState";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function AccountOutsourcingBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="account-outsourcing-banner-section ">
      <div className="container">
        <div className="banner-grid">
          <div className="bannner-left-wrapper">
            <div className="bannner-left">
              <img src={CollebarteImg} alt="CollebarteImg" />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Accounts Outsourcing {state.country}</h1>
            <h2>
              At Finaccle we provide accounts outsourcing solutions, and it can
              help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Reducing overhead costs</li>
                <li>Accessing expert accounting services</li>
                <li>Enhancing operational efficiency</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h1>What is Outsourced Accounting?</h1>
          <p>
            The process of employing outside experts or a third-party service
            provider to manage different accounting duties and obligations on
            behalf of a company is known as accounts outsourcing. <br />
            Rather than handling accounting functions internally, companies can
            contract out these jobs to specific companies or individuals with
            the requisite knowledge and resources.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
