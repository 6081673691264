import React, { useContext } from "react";
import "./AccountOutsourcingBanner.scss";
import CollebarteImg from "../../../assets/images/budgeting-banner.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { GlobalContext } from "../../../store/GlobalState";

export default function AccountOutsourcingBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="account-outsourcing-banner-section ">
      <div className="container">
        <div className="banner-grid">
          <div className="bannner-left-wrapper">
            <div className="bannner-left">
              <img src={CollebarteImg} alt="CollebarteImg" />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Budgeting and Forecasting {state.country}</h1>
            <h2>
              At Finaccle, we deliver expert budgeting and forecasting services,
              and it can help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Creating detailed financial plans and budgets</li>
                <li>Predicting future financial trends and outcomes</li>
                <li>Setting and achieving financial goals</li>
                <li>Implementing strategic financial management practices</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>WHAT IS BUDGETING AND FORECASTING IN FINANCE?</h2>
          <p>
            Budgeting and forecasting in finance involves the process of
            planning and predicting a company's financial performance and future
            outcomes. Budgeting refers to the creation of a detailed financial
            plan that outlines expected income, expenses, and resource
            allocation over a specific period. Forecasting, on the other hand,
            involves using historical data and market analysis to predict future
            financial trends and performance. These processes enable companies
            to set financial goals, make informed decisions, and ensure
            effective financial management.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
