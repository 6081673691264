import React from "react";
import "./PayableServices.scss";
import ServicesImg1 from "../../../assets/images/services-5.png";
import ServicesImg2 from "../../../assets/images/confidentiality-bro.svg";
import ServicesImg3 from "../../../assets/images/services-1.png";
import ServicesImg4 from "../../../assets/images/Experts-bro.svg";
import ServicesImg5 from "../../../assets/images/services-5.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function PayableServices() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="payableServicesSection">
      <div className="container">
        <div className="payableServicesHeading">
          <h2>Benefits of Hiring Finaccle for Invoice Processing Services</h2>
        </div>

        <div
          className="payableServicesDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Efficient</h3>
                <p>
                  We offer efficient invoice processing with accuracy even
                  within short period of time. We have expertise and technology
                  to process invoices quickly and accurately. This results in
                  faster turnaround times and improved efficiency in your
                  accounts payable department.
                </p>
              </div>
            </AnimatedSection>
            <AnimatedSection animationType={isMobile ? "fade" : "fade-left"} duration="600">
              <div className="payableServices-details-img">
                <img src={ServicesImg1} alt="ServicesImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg2} alt="ServicesImg2" />
              </div>
            </AnimatedSection>

            <AnimatedSection animationType={isMobile ? "fade" : "fade-left"} duration="600">
              <div className="payableServices-details-alignment">
                <h3>Confidentiality</h3>
                <p>
                  Our data security measures ensure complete confidentiality of
                  your information, ensuring your privacy and security.
                  outsourcing contracts include confidentiality agreements or
                  non-disclosure clauses, legally binding to maintain the
                  confidentiality of invoices and other sensitive information.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Cost Savings</h3>
                <p>
                  Outsourcing invoice processing can be more cost-effective than
                  handling it in-house. By outsourcing, you can save on expenses
                  related to hiring and training employees, infrastructure, and
                  software costs. Save on potential expenses associated with
                  hiring in-house invoice processors and consider the long-term
                  cost implications.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection animationType={isMobile ? "fade" : "fade-left"} duration="600">
              <div className="payableServices-details-img">
                <img src={ServicesImg3} alt="ServicesImg3" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg4} alt="ServicesImg4" />
              </div>
            </AnimatedSection>

            <AnimatedSection animationType={isMobile ? "fade" : "fade-left"} duration="600">
              <div className="payableServices-details-alignment">
                <h3>Qualified Team</h3>
                <p>
                  We have highly skilled professionals who specialize in invoice
                  processing. By outsourcing, you gain access to expertise and
                  best practices, which can help streamline your processes and
                  improve accuracy. Our team of experienced and qualified
                  invoice processing experts ensures an accuracy level of 99.9%.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Time Efficiency</h3>
                <p>
                  Outsourcing allows businesses to concentrate on core
                  activities, while experts handle invoice processing tasks,
                  increasing productivity and efficiency. This allows internal
                  teams to focus on strategic initiatives rather than routine
                  administrative tasks.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection animationType={isMobile ? "fade" : "fade-left"} duration="600">
              <div className="payableServices-details-img">
                <img src={ServicesImg5} alt="ServicesImg5" />
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
