import React from "react";
import "./AutomationServices.scss";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function AutomationServices() {
  return (
    <div className="automation-services-section">
      <div className="container">
        <div className="automation-services-heading">
          <h2>Invoice Processing Services We Offer</h2>
          <p>We provide the following Invoice Processing Services</p>
        </div>

        <div
          className="automation-services-details-alignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <AnimatedSection animationType="fade-up" duration="1000">
            <div className="automation-billing-services-grid">
              <div className="automation-services-box">
                <div className="link">
                  <a>High-end invoice processing software</a>
                  <a>Matching invoices with purchase orders</a>
                  <a>Crafting receipts for the e-invoices</a>
                  <a>Testing, handling & cashing</a>
                </div>
              </div>

              <div className="automation-services-box">
                <div className="link">
                  <a>Auditing of scanned invoices</a>
                  <a>Obtaining scanned invoices from the client</a>
                  <a>Reconciliation of audited scanned invoices</a>
                  <a>Elaborate tracking of every transaction</a>
                </div>
              </div>

              <div className="automation-services-box">
                <div className="link">
                  <a>Manual as well as automated Data Entry</a>
                  <a>Preparing PO receipts for supplier invoices</a>
                  <a>Document Indexing</a>
                  <a>Generating extensive reports</a>
                </div>
              </div>
            </div>
          </AnimatedSection>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
