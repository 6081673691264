import React from "react";
import "./PayableServices.scss";
import ServicesImg1 from "../../../assets/images/services-1.png";
import ServicesImg2 from "../../../assets/images/Experts-bro.svg";
import ServicesImg3 from "../../../assets/images/flexibility.svg";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function PayableServices() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="payableServicesSection">
      <div className="container">
        <div className="payableServicesHeading">
          <h2>Advantages</h2>
        </div>

        <div
          className="payableServicesDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Improved Order Accuracy and Efficiency with Finaccle</h3>
                <p>
                  Finaccle's customer order processing solutions improve order
                  accuracy and efficiency, reducing errors and delays.
                </p>
                <p>
                  Businesses can fulfill customer orders promptly, enhancing
                  satisfaction and loyalty with Finaccle's streamlined
                  processes.
                </p>
              </div>
            </AnimatedSection>
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg1} alt="ServicesImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg2} alt="ServicesImg2" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Enhanced Customer Experience Supported by Finaccle</h3>
                <p>
                  Finaccle's efficient order processing enhances the overall
                  customer experience by ensuring timely order fulfillment and
                  delivery.
                </p>
                <p>
                  With Finaccle, businesses can meet customer expectations and
                  exceed service levels, fostering positive relationships and
                  repeat business.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>
                  Scalability and Flexibility in Order Processing with Finaccle
                </h3>
                <p>
                  Finaccle's order processing solutions are scalable and
                  flexible, accommodating businesses of all sizes and
                  industries.
                </p>
                <p>
                  Whether experiencing seasonal fluctuations or rapid growth,
                  Finaccle adapts to changing demands and volumes seamlessly.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg3} alt="ServicesImg3" />
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
