import React from "react";
import "./PayableServices.scss";
import ServicesImg1 from "../../../assets/images/confidentiality-bro.svg";
import ServicesImg2 from "../../../assets/images/mistake.svg";
import ServicesImg3 from "../../../assets/images/services-3.png";
import ServicesImg4 from "../../../assets/images/services-4.png";
import ServicesImg5 from "../../../assets/images/services-5.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function PayableServices() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="payableServicesSection">
      <div className="container">
        <div className="payableServicesHeading">
          <h2>Advantages of performing Periodic Reconciliation</h2>
        </div>

        <div
          className="payableServicesDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Identifying Fraudulent Activity</h3>
                <p>
                  Reconciliation aids in identifying fraudulent activity by
                  detecting discrepancies or unauthorized transactions, which
                  can indicate fraudulent activities.
                </p>
              </div>
            </AnimatedSection>
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg1} alt="ServicesImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg2} alt="ServicesImg2" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Preventing Mistakes</h3>
                <p>
                  This system helps in detecting errors such as double payments,
                  missed payments, or lost cheques, thereby reducing the risk of
                  human error.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Receivables Tracking</h3>
                <p>
                  It is a crucial process that ensures accurate recording and
                  accounting of all transactions related to receivables.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg3} alt="ServicesImg3" />
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg4} alt="ServicesImg4" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Efficiency and Time Savings</h3>
                <p>
                  Automated reconciliation processes can enhance efficiency and
                  time savings by reducing financial close delays and providing
                  substantial time and cost benefits.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="payableServicesGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="payableServices-details-alignment">
                <h3>Improving Financial Reporting</h3>
                <p>
                  Reconciliation is a crucial internal control for businesses,
                  ensuring consistency and accuracy in financial reporting.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="payableServices-details-img">
                <img src={ServicesImg5} alt="ServicesImg5" />
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
