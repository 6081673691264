import React from "react";
import "./CatchupAccounting.scss";
import FeaturesCatch from "./FeaturesCatch/FeaturesCatch";
import CatchupAccountingBanner from "./CatchupAccountingBanner/CatchupAccountingBanner";
import WhatCatch from "./WhatCatch/WhatCatch";
import CatchOption from "./CatchOption/CatchOption";
import CatchTable from "./CatchTable/CatchTable";
import CatchServices from "./CatchServices/CatchServices";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import AllServices from "../../components/AllServices/AllServices";

export default function CatchupAccounting() {
  return (
    <div>
      <CatchupAccountingBanner />
      <WhatCatch />
      <FeaturesCatch />
      <CatchOption />
      <CatchTable />
      <CatchServices />
      <OurProcess />
      <TestimonialCards />

      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
      <div className="vistual-explore-button">
        <AllServices />
      </div>
      <div className="container">
        <LatestBlog />
      </div>
    </div>
  );
}
