import React from "react";
import "./AccountPayeble.scss";
import AccountPayebleBanner from "./AccountPayebleBanner/AccountPayebleBanner";
import PayableServices from "./PayableServices/PayableServices";
import AutomationServices from "./AutomationServices/AutomationServices";
import OurProcess from "../../components/OurProcess/OurProcess";
import TestimonialCards from "../../components/TestimonialCards/TestimonialCards";
import SoftwareExpertise from "../../components/SoftwareExpertise/SoftwareExpertise";
import LatestBlog from "../../components/LatestBlog/LatestBlog";
import AllServices from "../../components/AllServices/AllServices";

export default function AccountPayeble() {
  return (
    <div>
      <AccountPayebleBanner />
      <PayableServices />
      <AutomationServices />
      <OurProcess />
      <TestimonialCards />

      <div className="software-expertise-main">
        <SoftwareExpertise />
      </div>
      <div className="vistual-explore-button">
        <AllServices />
      </div>
      <div className="container">
        <LatestBlog />
      </div>
    </div>
  );
}
