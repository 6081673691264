import React from "react";
import "./SoftwareExpertise.scss";
import logo1 from "../../assets/images/logo-1.svg";
import logo2 from "../../assets/images/logo-2.svg";
import logo3 from "../../assets/images/logo-3.svg";
import logo5 from "../../assets/images/logo-5.svg";
import logo6 from "../../assets/images/logo-6.svg";
import logo7 from "../../assets/images/logo-7.svg";
import logo8 from "../../assets/images/logo-8.svg";
export default function SoftwareExpertise() {
  const handleSoftwareNavigation = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="software-expertise-section">
      <div className="container">
        <h2>Software expertise</h2>
        <p>
          Unlock the power of streamlined financial management with our
          expertise in industry-leading accounting software
        </p>
      </div>

      <div className="expertise-logo-details">
        <div
          className="logo-alignment"
          onClick={() =>
            handleSoftwareNavigation("https://quickbooks.intuit.com/")
          }
        >
          <img src={logo1} alt="Quick Books" />
        </div>
        <div
          className="logo-alignment"
          onClick={() => handleSoftwareNavigation("https://www.hubdoc.com/")}
        >
          <img src={logo2} alt="Hubdoc" />
        </div>
        <div
          className="logo-alignment"
          onClick={() => handleSoftwareNavigation("https://www.xero.com/")}
        >
          <img src={logo3} alt="Xero" />
        </div>
        <div
          className="logo-alignment"
          onClick={() => handleSoftwareNavigation("https://www.zoho.com/")}
        >
          <img src={logo5} alt="Zoho" />
        </div>
        <div
          className="logo-alignment"
          onClick={() =>
            handleSoftwareNavigation("https://tallysolutions.com/")
          }
        >
          <img src={logo6} alt="Tally" />
        </div>
        <div
          className="logo-alignment"
          onClick={() =>
            handleSoftwareNavigation(
              "https://www.netsuite.com/portal/home.shtml"
            )
          }
        >
          <img src={logo7} alt="NetSuite" />
        </div>
        <div
          className="logo-alignment"
          onClick={() => handleSoftwareNavigation("https://www.odoo.com/")}
        >
          <img src={logo8} alt="Odoo" />
        </div>
      </div>
    </div>
  );
}
