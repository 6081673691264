import React from "react";
import "./HiringVirtualCFO.scss";
import PricingImg1 from "../../../assets/images/hiring-1.png";
import PricingImg2 from "../../../assets/images/hiring-2.png";
import PricingImg3 from "../../../assets/images/hiring-3.png";
import PricingImg4 from "../../../assets/images/hiring-4.png";
import PricingImg5 from "../../../assets/images/hiring-5.png";
import PricingImg6 from "../../../assets/images/hiring-6.png";
import PricingImg7 from "../../../assets/images/hiring-7.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { useMediaQuery } from "@mui/material";

export default function HiringVirtualCFO() {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="hiringVirtualCfoSection">
      <div className="container">
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>When do you need a virtual CFO?</h2>
          <p>
            With the expansion of business, your workload grows exponentially.
            This creates holes in several areas of your business process. It
            also presents the difficulty of bringing experience on board, but
            you may not want to hire a full-time CFO due to the financial
            commitment, risk, or long-term consequences. This is where a virtual
            CFO may help you. Startups can benefit greatly from virtual CFOs.
            Virtual CFOs for startups provide the promoter with the much-needed
            financial strategic advice while incurring high costs.
          </p>
          <p>
            Having an outsourced CFO can benefit both small firms and startups.
            When hiring an outsourced CFO, make sure they are familiar with the
            financial details of the industry in which your company operates.
            Also, because the service is virtual, you can choose global experts
            who offer virtual CFO services in India. This will also allow you to
            extend your firm across regional limits. Virtual outsourced CFOs are
            typically finance-savvy.
          </p>
        </div>
      </div>

      <BookCallBannerButton />

      <div className="container">
        <div
          className="hiringVirtualCfoHeading"
          style={{
            paddingTop: "90px",
          }}
        >
          <h2>Need for Hiring a Virtual CFO</h2>
          <p>
            Hiring a Virtual CFO provides your firm with strategic counsel when
            your business is ready to move to the next stage of growth without
            incurring financial commitment or risk.
          </p>
          <p>A few other reasons why you should hire a Virtual CFO are:</p>
        </div>
      </div>

      <div className="container">
        <div
          className="hiringVirtualCfoDetailsAlignment"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Cost-Effectiveness</h3>
                <p>
                  In most cases, virtual CFO services are less expensive than
                  hiring a full-time CFO. Businesses can hire high-level
                  financial experts on a part-time or as-needed basis, lowering
                  overhead expenditures such as pay, benefits, and office space.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg1} alt="PricingImg1" />
              </div>
            </AnimatedSection>
          </div>
          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg2} alt="PricingImg2" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Access to Expertise</h3>
                <p>
                  Virtual CFOs are seasoned financial professionals with
                  considerable expertise across multiple industries. They have
                  extensive experience in financial strategy, budgeting,
                  forecasting, risk management, and other essential areas of
                  finance. This knowledge can help firms make sound judgments
                  and overcome complicated financial challenges.
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Strategic Guidance</h3>
                <p>
                  A virtual CFO can give strategic advice and financial
                  leadership to help firms accomplish their objectives. They can
                  help build long-term financial planning, optimize capital
                  structure, assess investment opportunities, and connect
                  financial strategy with overall corporate goals.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg3} alt="PricingImg3" />
              </div>
            </AnimatedSection>
          </div>
          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg4} alt="PricingImg4" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Scalability</h3>
                <p>
                  Virtual CFO services allow firms to alter the level of support
                  based on their changing needs. Whether during moments of
                  expansion, restructuring, or financial difficulty,
                  organizations can obtain extra expertise or scale back
                  services as needed, without having to hire or fire employees
                </p>
              </div>
            </AnimatedSection>
          </div>
          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Financial Analysis and Reporting</h3>
                <p>
                  Virtual CFOs can assist firms in improving financial reporting
                  and analysis by giving useful insights into key performance
                  indicators (KPIs), financial patterns, and opportunities for
                  development. They can create bespoke reporting frameworks,
                  dashboards, and KPIs to help management and stakeholders make
                  more informed decisions.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg5} alt="PricingImg5" />
              </div>
            </AnimatedSection>
          </div>
          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg6} alt="PricingImg6" />
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Access to Expertise</h3>
                <p>
                  Virtual CFOs are seasoned financial professionals with
                  considerable expertise across multiple industries. They have
                  extensive experience in financial strategy, budgeting,
                  forecasting, risk management, and other essential areas of
                  finance. This knowledge can help firms make sound judgments
                  and overcome complicated financial challenges.
                </p>
              </div>
            </AnimatedSection>
          </div>

          <div className="hiringGrid">
            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-right"}
              duration="600"
            >
              <div className="hiring-details-alignment">
                <h3>Risk Management</h3>
                <p>
                  Virtual CFOs play an important role in detecting and reducing
                  financial risks to the firm. They can evaluate internal
                  controls, create risk management frameworks, and devise ways
                  to protect assets, improve compliance, and reduce exposure to
                  financial vulnerabilities.
                </p>
              </div>
            </AnimatedSection>

            <AnimatedSection
              animationType={isMobile ? "fade" : "fade-left"}
              duration="600"
            >
              <div className="hiring-details-img">
                <img src={PricingImg7} alt="PricingImg7" />
              </div>
            </AnimatedSection>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
