import React, { createContext, useReducer } from "react";

// Create the context
export const GlobalContext = createContext();

// Define the initial state
const initialState = {
  country: "UK",
};

// Define a reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUE":
      return { ...state, country: action.payload };
    default:
      return state;
  }
};

// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
