import React, { useContext } from "react";
import "./CatchupAccountingBanner.scss";
import BannerImg from "../../../assets/images/Team-bro.svg";
import TeamImg from "../../../assets/images/retro-team.svg";
import { GlobalContext } from "../../../store/GlobalState";

export default function CatchupAccountingBanner() {
  const { state } = useContext(GlobalContext);

  return (
    <div className="catchup-accounting-banner-section">
      <div className="container">
        <div className="catchup-accounting-banner-grid">
          <div className="banner-img">
            <img src={BannerImg} alt="BannerImg" />
          </div>
          <div className="virtual-banner-text">
            <h1>Retro Accounting {state.country}</h1>
            <h2>
              At Finaccle we provide retro accounting solutions, and it can help
              you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Reviewing and correcting past financial records</li>
                <li>Ensuring historical accuracy</li>
                <li>Enhancing your financial foundation</li>
                <li>Implementing best financial practices for your business</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="hiringVirtualCfoHeading"
          style={{
            paddingBottom: "90px",
          }}
        >
          <h2>Retro Accounting</h2>
          <p>
            You've got a team of professionals on your side supported by robust
            software!
          </p>
          <p>
            Discover your dream team of small business specialists. With online
            accounting and genuine human assistance, it's the top-notch
            financial solution your business needs.
          </p>
        </div>
        <div className="catchup-accounting-bottom-alignment">
          <div className="catchup-accounting-bottom-grid">
            <div className="catchup-team-details">
              <h2>Is your business falling behind in keeping good records?</h2>
              <p>
                This is your chance to brush up on your accounting and get back
                into the industry.
              </p>
            </div>
            <div className="team-img-wrapper">
              <div className="team-img">
                <img
                  src={TeamImg}
                  alt="TeamImg"
                  style={{
                    width: "100%",
                    height: "250px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
