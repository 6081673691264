import React from "react";
import "./Blogs.scss";
import { useNavigate } from "react-router-dom";
import { blogListData } from "../../constants/blogs";

function Blogs() {
  const navigate = useNavigate();

  return (
    <div className="main-blogs-list-wrapper">
      <div className="container">
        <div className="blog-card-list-wrapper">
          {blogListData?.map((blog, index) => {
            return (
              <div className="card" key={index}>
                <div className="card-image-wrapper">
                  <img src={blog.blogImage} alt="John" />
                </div>
                <div className="card-body-wrapper">
                  <div className="card-content-wrapper">
                    <h2>{blog.blogTitle}</h2>
                    <p className="blog-description">{blog.blogDescription}</p>
                  </div>
                  <div className="card-button-wrapper">
                    <button
                      onClick={() =>
                        navigate(`/blogs/${blog.blogURL}`, {
                          state: "blogDetails",
                        })
                      }
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Blogs;
