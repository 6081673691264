import React, { useContext } from "react";
import "./AccountPayebleBanner.scss";
import AccountPayebleBannerImg from "../../../assets/images/payroll-banner.svg";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
import { GlobalContext } from "../../../store/GlobalState";

export default function AccountPayebleBanner() {
  const { state } = useContext(GlobalContext);
  return (
    <div className="account-payeble-banner-section">
      <div className="container">
        <div
          className="account-payeble-banner-grid"
          style={{
            marginBottom: "60px",
          }}
        >
          <div className="account-payeble-banner-img-wrapper">
            <div className="account-payeble-banner-img">
              <img
                src={AccountPayebleBannerImg}
                alt="AccountPayebleBannerImg"
              />
            </div>
          </div>
          <div className="virtual-banner-text">
            <h1>Payroll {state.country}</h1>
            <h2>
              At Finaccle, we provide comprehensive payroll solutions, and it
              can help you with:
            </h2>
            <div className="service-list-wrapper">
              <ul>
                <li>Accurately calculating employee wages and deductions</li>
                <li>Ensuring timely and compliant payroll processing</li>
                <li>Managing payroll taxes and filings</li>
                <li>
                  Implementing efficient payroll systems for your business
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="hiringVirtualCfoHeading"
          style={{
            marginBottom: "60px",
          }}
        >
          <h2>Payroll Management</h2>
          <p>
            Payroll is a crucial operating expense for businesses, as it
            calculates the total amount a company needs to pay employees for a
            specific period. It is essential for small and medium businesses to
            pay employees on time and accurately, as it can lead to employee
            dissatisfaction and government penalties. Payroll management can be
            time-consuming, involving tasks like mapping timesheets, preparing
            employee pay slips, PF and much more. Outsourcing payroll management
            is the best way to ensure a smooth operation and avoid potential
            issues.
          </p>
          <p>
            We offer cost-effective and scalable solutions for businesses to
            manage their payroll processes. Our services provide accountability
            for payroll management, compliance, and taxation, ensuring a
            streamlined and efficient payroll administration.
          </p>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
