import React from "react";
import "./WhatVirtualCfo.scss";

export default function WhatVirtualCfo() {
  return (
    <div className="container">
      <div className="what-virtual-cfo-section">
        <div className="what-virtual-cfo-heading">
          <h2>What A Virtual CFO Can Do</h2>
        </div>

        <div className="what-virtual-cfo-details-alignment">
          <div className="what-virtual-grid">
            <div className="what-virtual-cfo-box">
              <h4>Financial Strategy and Planning</h4>
              <p>
                Develop and execute financial strategies that are consistent
                with the overall aims and objectives of the firm. This involves
                long-term financial planning, budgeting, forecasting, and cash
                flow management.
              </p>
            </div>
            <div className="what-virtual-cfo-box">
              <h4>Financial Analysis and Reporting</h4>
              <p>
                Examine financial data, key performance indicators (KPIs), and
                trends to provide insights and recommendations for enhancing
                financial performance. Prepare and distribute accurate and
                timely financial reports to management, investors, and other
                stakeholders.
              </p>
            </div>
            <div className="what-virtual-cfo-box">
              <h4>Compliance and regulatory oversight</h4>
              <p>
                It include ensuring that financial regulations, accounting
                standards, and reporting obligations are met. Keep track of
                developments in the regulatory environment and advise on the
                implications for the firm.
              </p>
            </div>
            <div className="what-virtual-cfo-box">
              <h4>Risk management</h4>
              <p>
                It involves identifying, assessing, and mitigating financial
                risks that may damage the firm. Create risk management methods
                and internal control frameworks to protect assets, improve
                compliance, and reduce financial vulnerabilities.
              </p>
            </div>
            <div className="what-virtual-cfo-box">
              <h4>Financial Modeling and Forecasting</h4>
              <p>
                Build financial models to simulate numerous scenarios and
                evaluate their influence on cash flow, profitability, and
                valuation. Use forecasting tools to predict future financial
                consequences and drive decision-making.
              </p>
            </div>
            <div className="what-virtual-cfo-box">
              <h4>Cost Management and Efficiency Improvement</h4>
              <p>
                Identify opportunities to decrease costs, improve efficiency,
                and increase profitability in all areas of the organization.
                Implement cost-cutting efforts, process enhancements, and
                performance indicators to achieve operational excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
