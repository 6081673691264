import React from "react";
import "./ProvideFinaccle.scss";
import Icon1 from "../../../assets/icons/user-expert.png";
import Icon2 from "../../../assets/icons/data-analysis.png";
import Icon3 from "../../../assets/icons/risk-mitigation.png";
import Icon4 from "../../../assets/icons/cash-flow.png";
import Icon5 from "../../../assets/icons/performance.png";
import Icon6 from "../../../assets/icons/cost-effective.png";
import Icon7 from "../../../assets/icons/handshake.png";
import Icon8 from "../../../assets/icons/data-analysis.png";
import Icon9 from "../../../assets/icons/report-success.png";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";
export default function ProvideFinaccle() {
  return (
    <div className="provide-finaccle-section">
      <div className="container">
        <h2> What we provide at Finaccle</h2>
        <div className="provide-finaccle-details-alignment">
          <div className="provide-finaccle-child-details">
            <div className="flex-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Expert Guidance</h4>
                <p>
                  Access to financial experts specialized in cash flow
                  management. customized to your business needs.
                </p>
                <p>
                  Tailored insights and recommendations aligned with your
                  business goals.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon2} alt="Icon1" />
                <h4>Strategic Planning</h4>
                <p>Collaborative development of strategic cash flow plans.</p>
                <p>
                  Analysis of past data and forecasting for future cash flow
                  optimization.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon3} alt="Icon1" />
                <h4>Risk Mitigation:</h4>
                <p>
                  Identification and mitigation of financial risks associated
                  with cash flow fluctuations.
                </p>
                <p>
                  Implementation of strategies to minimize impact on business
                  operations.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon4} alt="Icon1" />
                <h4>Optimized Cash Flow</h4>
                <p>
                  Utilization of techniques to optimize cash flow, such as
                  accelerating receivables and managing payables.
                </p>
                <p>
                  Ensuring sufficient liquidity to meet short-term obligations
                  and support business growth.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon5} alt="Icon1" />
                <h4>Performance Monitoring</h4>
                <p>Continuous monitoring of cash flow performance.</p>
                <p>
                  Provision of regular reports and analysis to track progress
                  and identify areas for improvement.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
            }}
          >
            <h3>Advantages</h3>
            <div className="provide-finaccle-grid two-provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon1} alt="Icon1" />
                <h4>Access to Specialized Expertise</h4>
                <p>
                  Gain access to a team of financial experts with specialized
                  knowledge in cash flow management.
                </p>
                <p>
                  Benefit from their experience and insights to navigate complex
                  financial challenges effectively.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon3} alt="Icon1" />
                <h4>Risk Mitigation and Compliance</h4>
                <p>
                  Mitigate financial risks associated with cash flow
                  fluctuations.
                </p>
                <p>
                  Ensure compliance with financial regulations and legal
                  obligations relevant to cash flow management.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>Cost Efficiency and Scalability</h4>
                <p>
                  Cost-effective alternative to hiring in-house financial
                  experts.
                </p>
                <p>
                  Scalable services tailored to the specific needs and growth
                  objectives of your business.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon7} alt="Icon1" />
                <h4>Strategic Partnerships and Support</h4>
                <p>
                  Establish a long-term partnership with Finaccle, serving as
                  trusted advisors in cash flow management.
                </p>
                <p>
                  Receive ongoing support and guidance to achieve financial
                  success and business growth.
                </p>
              </div>
            </div>
          </div>

          <div
            className="provide-finaccle-child-details"
            style={{
              marginTop: "100px",
              marginBottom: "60px",
            }}
          >
            <h3>Why Choose Finaccle </h3>
            <div className="provide-finaccle-grid">
              <div className="box-alignment">
                <img src={Icon6} alt="Icon1" />
                <h4>Cost effective service</h4>
                <p>
                  At Finaccle, we understand the importance of
                  cost-effectiveness in today's competitive business landscape.
                  That's why we are committed to providing budgeting services
                  that offer exceptional value without compromising on quality.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon8} alt="Icon1" />
                <h4>Tailored Solutions and Personalized Service</h4>
                <p>
                  Finaccle offers customized solutions tailored to the unique
                  needs and objectives of each client.
                </p>
                <p>
                  Receive personalized service and dedicated support to address
                  your specific cash flow management challenges.
                </p>
              </div>
              <div className="box-alignment">
                <img src={Icon9} alt="Icon1" />
                <h4>Focus on Your Success</h4>
                <p>
                  At Finaccle, we prioritize your success and strive to help you
                  achieve your financial goals.
                </p>
                <p>
                  Our proactive approach and commitment to excellence ensure
                  that your cash flow management needs are met with utmost
                  efficiency and professionalism.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookCallBannerButton />
    </div>
  );
}
