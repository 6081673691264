import React from "react";
import "./FeaturesCatch.scss";
export default function FeaturesCatch() {
  return (
    <div className="container">
      <div className="features-cash-section">
        <div className="features-cash-heading">
          <h2>Features of Catch up Accounting</h2>
        </div>

        <div className="features-cash-details-alignment">
          <div className="features-cash-tab-details">
            <div className="row-alignment">
              <div className="tab-alignment">
                <p>Tidy tax-ready financials</p>
              </div>
              <div className="tab-alignment">
                <p>Fast Turnaround times</p>
              </div>
            </div>

            <div className="row-alignment">
              <div className="tab-alignment">
                <p>Updated FS </p>
              </div>
              <div className="tab-alignment">
                <p>Swift, Dedicated bookkeeping Expert</p>
              </div>
            </div>
            <div className="row-alignment">
              <div className="tab-alignment">
                <p>Rapid restoration of bookkeeping</p>
              </div>
              <div className="tab-alignment">
                <p>Fit for financial relief</p>
              </div>
            </div>
            <div className="row-alignment">
              <div className="tab-alignment">
                <p>Tidy, Tax-ready Documentation </p>
              </div>
              <div className="tab-alignment">
                <p>Your own experienced team</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
