import React from "react";
import "./CatchTable.scss";
import BookCallBannerButton from "../../../components/BookCallBannerButton/BookCallBannerButton";

export default function CatchTable() {
  return (
    <>
      <div className="container">
        <div
          className="dedication-section"
          style={{
            padding: 0,
          }}
        >
          <h3>Seeking assistance with historical accounting?</h3>
          <p>
            Our team of professional bookkeepers helps bring your books
            up-to-date Need historical accounting services spanning over two
            years or even more? Our Finaccle Retro experts are here to assist
            you in catching up to get your books in order
          </p>
        </div>
      </div>

      <div
        style={{
          marginTop: "90px",
        }}
      >
        <BookCallBannerButton />
      </div>
    </>
  );
}
